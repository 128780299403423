import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function FooterAlert({
    alertType = 'naia_njcaa', //
    alertText = '', //
    conferenceId, //
    color = null, //
    secs = 5, //
    overrideStyle, //
    showCountdown = true //         tue/false to show the countdown text
}) {
    // set state (ability to X out of Alert)
    const [showAlert, setShowAlert] = useState(true);
    const [countdown, setCountdown] = useState(secs);

    // Add useEffect for showing text of countdown before alert disappears
    useEffect(() => {
        if (secs >= 1 && countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
        return () => {};
    }, [countdown, secs]);

    // set some styles
    const alertStyles = {
        backgroundColor: color || '#0066CC',
        animationDelay: secs >= 1 ? `${secs}s` : null,
        animation: secs >= 1 ? `hideAfterDelay ${secs}s linear forwards` : null,
        ...(overrideStyle && overrideStyle)
    };
    const anchorStyles = {
        marginLeft: 5,
        color: 'white',
        textDecoration: 'underline #CC6600',
        textDecorationStyle: 'double'
    };

    // And Return
    return (
        <div className={`footer-alert ${!showAlert && 'hidden'}`} style={alertStyles}>
            <div style={{ opacity: 0 }}>.</div>
            <div className='footer-wrapper-div' style={{ display: 'flex', alignItems: 'center', lineHeight: 1.1 }}>
                {alertType === 'naiaNjcaa' && conferenceId === 0 && <>
                    <p>
                        The only NAIA and NJCAA data currently available is from games played against D-I, II or III
                        teams. Unfortunately, we do not have the raw data for most other NAIA and NJCAA games to be
                        able to include them on the platform.
                    </p>
                </>}
                {alertType === 't1TrialT2' && <>
                    <p>
                        Congrats on your trial, enjoy for the next couple of weeks
                    </p>
                </>}
                {alertType === 'freeTier' && <>
                    <p style={{ textAlign: 'center' }}>
                        Consider upgrading to User Tier in your
                        <span>
                            <Link style={anchorStyles} to='/my-account/subscribe'>Account Settings</Link>!
                        </span>
                    </p>
                </>}
                {alertType === 'submitSurveyForFreeWeek' && <>
                    <p style={{ textAlign: 'center' }}>
                        Get a free Week of User Tier by completing a short
                        <a style={anchorStyles} target='_blank' rel='noreferrer' href='https://forms.gle/qrnCuMsacyQaCXG47'>
                            Feedback Survey!
                        </a>
                    </p>
                </>}
                {alertType === 'signupForMarchMadnessTourney' && <>
                    <p>
                        Win Cash Prizes & CBB Subscriptions, Sign up for our Free!
                    </p>
                    <a style={anchorStyles} target='_blank' rel='noreferrer' href='https://picks.cbssports.com/college-basketball/ncaa-tournament/bracket/pools/kbxw63b2ha3dmmbvha4a====/standings'>
                        March Madness Bracket Challenge
                    </a>
                </>}
                {alertType === 'marchMadness' && <>
                    <p>Our</p>
                    <Link style={anchorStyles} to='/march-madness'>Tournament Brackets</Link>
                    <p style={{ marginLeft: 5 }}>are live for Conference Tournaments, coming soon for the NCAA Tournament!</p>
                </>}
                {alertType === 'custom' &&
                    <p>{alertText}</p>
                }

                {/* countdown text */}
                {showCountdown && secs >= 1 &&
                    <div style={{ marginRight: 5 }}>[{countdown - 1}s]</div>
                }
            </div>
            <div className='alert-x' onClick={() => setShowAlert(false)}>
                X
            </div>
        </div>
    );
}

export default FooterAlert;
