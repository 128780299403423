
// Import React Components
import React from 'react';
import { Row } from 'react-bootstrap';

// Import CBB Utils...
import ForgotPassword from '../ForgotPassword.js';


// Create The Component
function MyAccountResetPassword() {
    // shared section styles
    let sectionRowStyles = {
        borderBottom: '2px solid #222222',
        margin: 0,
        paddingTop: 8,
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    };

    // and return !!
    return (<>
        <Row style={{ ...sectionRowStyles }}>
            <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                Reset Password
            </h3>
        </Row>
        <ForgotPassword />
    </>);
}

export default MyAccountResetPassword;
