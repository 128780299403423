import React from 'react';
import { RingLoader } from 'react-spinners';

function LoadingSpinner({
    wrapperStyle = {},
    size = 'big',
    width = null,
    text = 'statistics loading',
    text2 = '',
    color = '#0066CC'
}) {
    // set variables based on "size"
    let divStyle, pStyle, ringWidth, height;
    switch (size) {
        case 'big':
            divStyle = { margin: '35px 5px 0px 5px', display: 'flex', alignItems: 'flex-start', justifyContent: 'center' };
            pStyle = { zIndex: 10, margin: 0, paddingLeft: 8, fontStyle: 'italic', fontSize: '1.4em' };
            ringWidth = width || 34;
            height = 1500; break;
        case 'small':
            divStyle = { margin: '10px 3px', display: 'flex', alignItems: 'center' };
            pStyle = { zIndex: 10, margin: 0, paddingLeft: 4, fontStyle: 'italic', fontSize: '1.05em' };
            ringWidth = width || 20;
            height = 50; break;
        default:
            divStyle = { margin: '10px 3px 0px 3px', display: 'flex', alignItems: 'center' };
            pStyle = { zIndex: 10, margin: 0, paddingLeft: 4, fontStyle: 'italic', fontSize: '1.05em' };
            ringWidth = width || 20;
            height = 50; break;
    }

    let loadingBar = (
        <div style={{ height, ...wrapperStyle }}>
            <div style={divStyle}>
                <RingLoader
                    size={ringWidth}
                    color={color}
                    loading={true}
                    speedMultiplier={1}
                />
                <p style={pStyle}>{text}</p>
            </div>
            {text2 && <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                <p style={pStyle}>{text2}</p>
            </div>}
        </div>
    );

    // and return
    return (loadingBar);
}

export default LoadingSpinner;
