
import React from 'react';
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

function NoMatch() {
    // useNavigate
    const navigate = useNavigate();

    // return home button
    const returnHomeButton = (<Link
        to='/'
        style={{ marginLeft: 12, display: 'inline-block', padding: '8px 16px', background: '#ddd', color: '#333', borderRadius: 4, textDecoration: 'none', fontWeight: 500 }}
    >
        Return to Home
    </Link>);

    // go back button
    const goBackButton = (<button
        onClick={() => navigate(-1)}
        style={{ marginLeft: 12, padding: '8px 16px', background: '#0066CC', border: 'none', color: 'white', borderRadius: 4, cursor: 'pointer', fontWeight: 500 }}
    >
        Go Back
    </button>);

    // and return
    return (
        <Container className='shadow-container' style={{ maxWidth: 800, margin: '45px auto 25px' }}>
            <div>
                <h3 className='cbb-policy-top-header'>Error: 404</h3>
                <p><strong>The page you were trying to reach does not exist.</strong></p>
            </div>
            <div>
                <p style={{ marginTop: 15 }}>Please return back to the previous page from which you came.
                    If there is an issue with the website that led you to this page, which there may be, since you have arrived on an error page after all, you can send an email reporting the issue to info@cbbanalytics.com.</p>
            </div>
            <div style={{ marginTop: 24 }}>
                {goBackButton}
                {returnHomeButton}
            </div>
        </Container>
    );
}

export default NoMatch;
