
// Overflow Hidden on "div.input", width: 110% (test different widths) on "div.input input"

// https://codesandbox.io/s/react-select-v3-autosize-d3j92?fontsize=14&hidenavigation=1&theme=dark&file=/src/App.js:1646-1728
// Dynamic sizing:
/* eslint-disable quote-props */

// control: the overall / primary styling of the select goes here
// option: in dropdown menu, the box containing the text for each option.
const cbbBoxShadow = '0 2px 2px 0 rgba(0,0,0,0.325), 0 1px 5px 0 rgba(0,0,0,0.325), 0 3px 1px -2px rgba(0,0,0,0.325)';

export const appSelectStyles = {
    control: (base, state) => ({
        ...base,
        cursor: 'text',
        // background: state.isDisabled ? 'rgba(167, 0, 0, 0.15)' : '#FFFFFF',
        background: state.isDisabled ? 'rgba(167, 0, 0, 0.15)' : '#CC6600',
        // border: state.isFocused ? '1px solid #222222' : '1px solid #222222',
        border: 'none',
        borderRadius: 0, // 0
        boxShadow: 'none',
        height: 48
    }),
    option: (base) => ({
        ...base,
        cursor: 'pointer',
        paddingTop: 1,
        paddingBottom: 1,
        marginTop: 1,
        marginBottom: 1
    }),
    menu: (base) => ({
        ...base,
        borderRadius: 0,
        background: '#FFFFFF',
        boxShadow: 'none',
        border: '1px solid #222222',
        marginTop: -1,
        zIndex: 999
    }),
    menuList: (base) => ({
        ...base,
        paddingTop: 0
    }),
    valueContainer: (base) => ({
        ...base,
        height: 36,
        color: '#222222'
    }),
    placeholder: (base) => ({
        ...base,
        fontSize: 18,
        opacity: 1,
        // color: '#333333',
        color: '#FFFFFF',
        fontWeight: 700
    }),
    singleValue: (base) => ({
        ...base,
        color: '#222222'
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        fontSize: 18,
        color: '#FFFFFF',
        overflow: 'hidden',
        'input': { width: '110% !important' }
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 6
    })
};
export const signupSelectStyles = {
    container: (base) => ({
        ...base,
        width: '100%'
    }),
    control: (base, state) => ({
        ...base,
        cursor: 'pointer',
        maxWidth: 375,
        border: '1px solid #777777',
        color: '#444444',
        backgroundColor: state.isDisabled ? 'rgba(167, 0, 0, 0.15)' : 'white',
        '&:hover': {
            backgroundColor: '#EEEEEE'
        }
    }),
    option: (base) => ({
        ...base,
        cursor: 'pointer',
        padding: '3px 8px 4px 8px',
        fontSize: '0.9em'
    }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    singleValue: (base) => ({ ...base, fontSize: '0.9em' }),
    placeholder: (base) => ({ ...base, color: '#555555', fontSize: '0.95em' }),
    input: (base) => ({
        ...base,
        overflow: 'hidden',
        'input': { width: '110% !important' }
    })
};
export const blackSelectStyles = () => {
    // currently not using "color", but still keep this as a function
    return {
        container: (base) => ({ ...base }),
        control: (base, state) => ({
            ...base,
            fontFamily: `"Roboto", sans-serif`,
            minHeight: 5,
            cursor: 'pointer',
            boxShadow: 'none',
            borderRadius: 5,
            border: '1px solid #222222',
            fontSize: '1.0em',
            backgroundColor: state.isDisabled ? 'rgba(167, 0, 0, 0.15)' : 'white',
            '&:hover': {
                backgroundColor: '#EEEEEE'
            },
            maxHeight: state.isFocused ? 'none' : 36,
            overflow: state.isFocused ? 'visible' : 'hidden'
        }),
        option: (base) => ({
            ...base,
            cursor: 'pointer',
            padding: '2px 6px 3px 6px',
            fontSize: '0.95em',
            marginTop: 0
        }),
        menu: (base) => ({
            ...base,
            minWidth: 'max-content',
            backgroundColor: 'white',
            border: '1px solid #222222',
            boxShadow: cbbBoxShadow,
            zIndex: 999
            // '@media (max-width: 992px)': {
            //     backgroundColor: '#222222'
            // }
        }),
        menuList: (base) => ({
            ...base,
            color: '#222222',
            maxHeight: 350
        }),
        singleValue: (base) => ({
            ...base,
            // fontSize: '1.0em',
            maxWidth: 'none',
            color: '#222222', // color: state.isDisabled ? 'white' : '#222222',
            overflow: 'initial'
        }),
        multiValue: (base) => ({
            ...base,
            textDecoration: 'underline',
            backgroundColor: 'none',
            borderRadius: 5,
            margin: 1,
            paddingLeft: 2,
            color: '#222222',
            fontSize: '0.95em',
            '&:hover': { backgroundColor: '#BBBBBB' },
            '.css-12jo7m5': {
                fontSize: '85%'
            }
        }),
        multiValueRemove: (base) => ({
            ...base,
            borderRadius: 5,
            paddingRight: 2,
            paddingLeft: 2,
            '&:hover': { color: 'white', backgroundColor: '#888' }
        }),
        placeholder: (base) => ({
            ...base,
            // fontSize: '1.0em',
            color: '#222222', // state.isDisabled ? 'white' : '#222222',
            overflow: 'initial'
        }),
        valueContainer: (base) => ({
            ...base,
            padding: '2px 0 0 6px',
            '@media (max-width: 992px)': { padding: '0 0 0 6px' },
            maxHeight: 'inherit'
        }),
        input: (base) => ({
            ...base,
            color: '#222222',
            overflow: 'hidden',
            '@media (max-width: 992px)': { margin: 1, padding: '1px 0 0 0' },
            'input': { width: '110% !important' }
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        indicatorsContainer: (base) => ({
            ...base,
            padding: 0,
            color: '#222222',
            maxHeight: 'inherit'
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: 0,
            color: '#222222' // state.isDisabled ? 'white' : '#222222'
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: '0px 6px 0 2px',
            color: '#222222' // state.isDisabled ? 'white' : '#222222'
        })
        // indicatorsContainer: (base) => ({}),
    };
};
export const filterSelectStyles = {
    ...blackSelectStyles(),
    control: (base, state) => ({
        ...blackSelectStyles().control(base, state),
        borderRadius: '5px 0px 0px 5px',
        height: 36,
        fontSize: '0.875em',
        background: 'white',
        border: state.isFocused ? '1px solid #0066CC' : '1px solid #888',
        boxShadow: state.isFocused ? '0 0 1px #0066CC' : 'none',
        zIndex: state.isFocused ? 100 : 1
    }),
    valueContainer: (base) => ({
        ...blackSelectStyles().valueContainer(base),
        height: '-webkit-fill-available' // fill to size of 'control'
    }),
    indicatorsContainer: (base) => ({
        ...blackSelectStyles().indicatorsContainer(base),
        display: 'none'
        // height: '-webkit-fill-available' // fill to size of 'control'
    }),
    option: (base, state) => ({
        ...blackSelectStyles().option(base, state),
        fontSize: '0.85em'
    }),
    input: (base) => ({
        ...base,
        overflow: 'hidden',
        'input': { width: '110% !important' }
    })
};
export const advFilterTextStyles = {
    ...blackSelectStyles(),
    control: (base, state) => ({
        ...blackSelectStyles().control(base, state),
        borderRadius: 0,
        height: 36,
        width: 60,
        fontSize: '0.875em',
        background: 'white',
        marginLeft: -1,
        border: state.isFocused ? '1px solid #0066CC' : '1px solid #888',
        boxShadow: state.isFocused ? '0 0 1px #0066CC' : 'none',
        zIndex: state.isFocused ? 100 : 1,
        cursor: 'pointer'
    }),
    valueContainer: (base) => ({
        ...blackSelectStyles().valueContainer(base),
        height: '-webkit-fill-available' // fill to size of 'control'
    }),
    indicatorsContainer: (base) => ({
        ...blackSelectStyles().indicatorsContainer(base),
        display: 'none'
        // height: '-webkit-fill-available' // fill to size of 'control'
    }),
    option: (base, state) => ({
        ...blackSelectStyles().option(base, state),
        fontSize: '0.85em'
    }),
    input: (base) => ({
        ...base,
        overflow: 'hidden',
        color: '#222222',
        'input': { width: '110% !important' }
    })
};
export const inTableMultiSelect = {
    ...blackSelectStyles(),
    container: (base) => ({
        ...base,
        minWidth: 120,
        // maxWidth: 250,
        fontSize: 11,
        marginLeft: 5
    }),
    valueContainer: (base) => ({
        ...base,
        padding: 0,
        maxHeight: 16
    }),
    input: (base) => ({
        ...base,
        margin: 0,
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: 3,
        overflow: 'hidden',
        'input': { width: '110% !important' }
    }),
    indicatorsContainer: (base) => ({
        ...base,
        // marginLeft: 15,
        paddingLeft: 15,
        color: '#AAAAAA'
    }),
    placeholder: (base) => ({
        ...base,
        marginLeft: 4
    }),
    multiValue: (base) => ({
        ...base,
        padding: 1,
        paddingLeft: 2,
        margin: 0,
        marginLeft: 2,
        lineHeight: 1.3,
        borderRadius: 3
    }),
    multiValueLabel: (base) => ({
        ...base,
        padding: '1px 3px',
        paddingLeft: 2
    }),
    multiValueRemove: (base) => ({
        ...base,
        borderRadius: 3,
        padding: '0px 1px',
        '&:hover': { color: 'white', backgroundColor: '#888' }
    })
};
export const liveSearchStyles = {
    ...blackSelectStyles(),
    control: (base, state) => ({
        ...blackSelectStyles().control(base, state),
        cursor: 'text'
    })
};
export const smallSelectStyles = {
    ...blackSelectStyles(),
    control: (base, state) => ({
        ...blackSelectStyles().control(base, state),
        fontSize: '0.9em'
    }),
    option: (base, state) => ({
        ...blackSelectStyles().option(base, state),
        fontSize: '0.85em'
    }),
    valueContainer: (base, state) => ({
        ...blackSelectStyles().valueContainer(base, state),
        padding: '1px 0 0 4px'
    }),
    input: (base, state) => ({
        ...blackSelectStyles().input(base, state),
        margin: 0,
        padding: 1
    })
};
export const directionalStyles = {
    ...blackSelectStyles(),
    control: (base, state) => ({
        ...blackSelectStyles().control(base, state),
        borderRadius: 0,
        height: 36,
        background: 'white',
        fontSize: '1.0em',
        border: state.isFocused ? '1px solid #0066CC' : '1px solid #888',
        boxShadow: state.isFocused ? '0 0 1px #0066CC' : 'none',
        zIndex: state.isFocused ? 100 : 1
    }),

    menuList: (base) => ({
        ...base
        // float: 'center',
        // textAlign: 'center'
        // maxHeight: 300
    }),
    indicatorsContainer: (base) => ({
        ...base,
        display: 'none'
    }),
    valueContainer: (base) => ({
        ...blackSelectStyles().valueContainer(base),
        height: '-webkit-fill-available', // fill to size of 'control'
        padding: '0px 8px'
    }),
    input: (base, state) => ({
        ...blackSelectStyles().input(base, state),
        color: 'transparent'
    })
};
