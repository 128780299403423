
// Import React Components
import React, { useState, useEffect, useContext } from 'react';
import { Row } from 'react-bootstrap';
import Axios from 'axios';

// Import Data Fetching Stuff
import config from '../../../config.js';
import GlobalContext from '../../../context/GlobalContext.js';

// Import CBB Utils...
import LoadingSpinner from '../../../components/uiUxItems/LoadingSpinner.js';


// Create The Component
function MyAccountOrders() {
    // useContext: Grab User Info
    const { userTier, userData } = useContext(GlobalContext);

    // useState: set page state
    const [gettingUserPayments, setGettingUserPayments] = useState(false);
    const [userPayments, setUserPayments] = useState([]);

    // Get all past stripe payments
    const getUserStripePayments = async () => {
        try {
            setGettingUserPayments(true);

            // "get-user-payments" route has req.user, used to find and return user's payments
            const apiBaseUrl = config.url.API_URL;
            const authHeader = { headers: { 'x-auth-token': userData.token } };
            const response = await Axios.get(`${apiBaseUrl}/stripe/get-user-payments`, authHeader);

            // if user has payments, set userPayments to the payments
            const hasPayments = response.data.hasPayments;
            setUserPayments(hasPayments ? response.data.paymentIntents.data : []);
            setGettingUserPayments(false);
        } catch (err) {
            console.log('err: ', err);
        }
    };

    // After userData loads,
    useEffect(() => {
        if (!userData.token) { return; }
        getUserStripePayments();
    }, [userData.token, userTier]);

    // display past orders in a <div> of <div>s
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let pastOrders = (<div style={{ maxWidth: 'max-content' }}>
        {userPayments.map((row, idx) => {
            let billDay = new Date(row.created * 1000);
            let dayString = `${monthNames[billDay.getMonth()]} ${billDay.getDate()}, ${billDay.getFullYear()}`;
            return (
                <div key={`payment-${idx}`} style={{ padding: '5px 10px', lineHeight: 1.3, border: '1px solid #222', borderRadius: 5, marginBottom: 5, background: 'darkgreen', opacity: 0.75 }}>
                    <p style={{ fontWeight: 700, fontSize: '0.9em', color: 'white' }}>
                        User Tier: Monthly ${row.amount / 100},
                        <span style={{ fontWeight: 300, fontSize: '0.9em', marginLeft: 4 }}>on {dayString}</span>
                    </p>
                </div>
            );
        })}
    </div>);

    // shared section styles
    let sectionRowStyles = {
        borderBottom: '2px solid #222222',
        margin: 0,
        paddingTop: 8,
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    };

    // and return !!
    return (<>
        <Row style={{ ...sectionRowStyles }}>
            <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                Latest Subscription Payments
            </h3>
        </Row>
        <Row style={{ margin: 0, marginTop: 12 }}>
            {gettingUserPayments && <LoadingSpinner size='small' text='fetching user payments...' />}
            {!gettingUserPayments && pastOrders}
        </Row>
    </>);
}

export default MyAccountOrders;
