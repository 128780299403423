
import React, { useEffect, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Axios from 'axios';
import GlobalContext from '../../context/GlobalContext';
import config from '../../config';

// Layout component that wraps all routes and logs 'pagechange' to the backend
// Tracks both the full URL path and a simplified version that replaces IDs with '/'.
// Only logs for authenticated users and non-homepage visits.
const RouteListenerLayout = ({ children }) => {
    const { pathname } = useLocation();
    const { userData } = useContext(GlobalContext);

    // Create Logging Function
    let userId = userData?.user?._id;
    let email = userData?.user?.email;
    const sendEventLog = async (shortPath, longPath) => {
        // Only send event log if we have a userId and email
        if (!userId || !email) { return; }

        let newEventLog = { userId, email, type: 'pagechange', shortPath, longPath };
        try {
            let apiBaseUrl = config.url.API_URL;
            await Axios.post(`${apiBaseUrl}/users/add-event-log`, newEventLog, {
                timeout: 5000 // 5 second timeout
            });
        } catch (err) {
            console.log('sendEventLog error: ', err);
        }
    };

    // UseEffect to log page change on each new pathname
    useEffect(() => {
        let shortPath = pathname.split('/').map(row => isNaN(row) ? row : '/').join('');
        let longPath = pathname;
        // log only if userId exists and pathname is not to the homepage
        if (userId && pathname !== '/') {
            sendEventLog(shortPath, longPath);
        }
    }, [pathname, userId]);

    // And Return children, which are the <Route />'s
    return children ?? <Outlet />;
};

export default RouteListenerLayout;
