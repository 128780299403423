

// Import React Components
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, FormControl, Alert } from 'react-bootstrap';
import Select from 'react-select';
import Axios from 'axios';

// Import Data Fetching Stuff
import config from '../../../config.js';
import GlobalContext from '../../../context/GlobalContext.js';
import useCBBQuery from '../../../hooks/useCBBQuery.js';

// Import CBB Utils...
import CBBSelect from '../../../components/selects/CBBSelect.js';
import Logo from '../../../components/uiUxItems/Logo.js';
import { signupSelectStyles } from '../../../utils/ReactSelectStyles.js';


// Create The Component
function MyAccountSettings() {
    // useContext: Grab User Info
    const { userTier, userData, setUserData } = useContext(GlobalContext);
    const utv = userTier.value;
    const isLoggedIn = userData.token ? true : false;
    const teamChangesRemaining = userData?.user?.teamChangesRemaining || 0;
    const noChangesRemaining = utv <= 1 && teamChangesRemaining === 0;
    const defaultTeamId = isLoggedIn ? userData.user.team.teamId : null;

    // useState: set page state
    const [showAlert, setShowAlert] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [conference, setConference] = useState(null);
    const [team, setTeam] = useState(null);
    const [gender, setGender] = useState(null);
    const [playerPctilesType, setPlayerPctilesType] = useState(null);
    const [shotChartColors, setShotChartColors] = useState(null);

    // Handle Setting Initial States Based On User's Current Settings
    useEffect(() => {
        if (userData.user) {
            setEmail(userData.user.email);
            setFirstName(userData.user.firstName);
            setLastName(userData.user.lastName);
            setTeam(userData.user.team);
            setConference(userData.user.conference);
            setGender(userData.user.gender);
            setPlayerPctilesType(userData.user.playerPctilesType);
            setShotChartColors(userData.user.shotChartColors || { value: 'blueToRed', label: 'Blue (Poor Shooting) to Red (Good Shooting)' });
        }
    }, [userData.user]);

    // Fetch Teams + Conferences Info
    const { data: conferencesInfo = [], isLoading: isLoading1, isError: isError1 } = useCBBQuery({ ep: 'conferences' });
    const { data: teamsInfo = null, isLoading: isLoading2, isError: isError2 } = useCBBQuery({ ep: 'teams' });
    // console.log('MyAccount Data Fetched: ', {teamsInfo, conferencesInfo});
    if (isError1 || isError2) { console.log('Data Fetching Error'); }
    const isLoading = (isLoading1 || isLoading2);

    // Submit Handler
    const handleUpdates = async () => {
        setIsUpdating(true);

        // Lets see what we can do
        try {
            // Make Axios Login Request
            const newChangesRemaining = (utv <= 1 && defaultTeamId !== team.value)
                ? teamChangesRemaining - 1
                : teamChangesRemaining;
            const updateUser = { firstName, lastName, team, conference, gender, playerPctilesType, shotChartColors, teamChangesRemaining: newChangesRemaining };
            const apiBaseUrl = config.url.API_URL;

            const id = userData.user ? userData.user._id : null;
            const updateResponse = await Axios.put(`${apiBaseUrl}/users/update/${id}`, updateUser);

            // Set States Following Successful Login
            setIsUpdating(false);
            setShowAlert(true);
            setUserData({
                token: userData.token,
                user: updateResponse.data
            });
        } catch (err) {
            setIsUpdating(false);
        }
        setIsUpdating(false);
    };

    // Form Controls, Selects, Buttons, Alerts, Logos
    // ===============================================
    // Form Controls (Full Name)
    const firstNameFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            name='firstName'
            placeholder='First Name'
        />);
    const lastNameFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            name='lastName'
            placeholder='Last Name'
        />);

    // Conference Select
    const conferenceOptions = conferencesInfo
        .sort((a, b) => { return (a.conferenceId < b.conferenceId ? -1 : 1); })
        .map(conf => ({ value: conf.conferenceId, label: conf.conferenceLongName }));

    const conferenceSelect = (
        <Select
            className='cbb-select'
            styles={signupSelectStyles}
            placeholder={'Conference Select'}
            isDisabled={conferenceOptions.length === 0 || noChangesRemaining || isLoading}
            value={conference}
            onChange={(e) => { setConference(e); setTeam(null); }}
            options={conferenceOptions}
        />);

    // League Select
    const leagueSelect =
        (<Select
            className='cbb-select'
            styles={signupSelectStyles}
            placeholder={'Select League'}
            value={gender}
            isDisabled={conferenceOptions.length === 0 || noChangesRemaining || isLoading}
            onChange={(e) => { setGender(e); setConference(null); setTeam(null); }}
            options={[{ value: 'MALE', label: `Men's NCAA Basketball` }, { value: 'FEMALE', label: `Women's NCAA Basketball` }]}
        />);

    // Team Select (.map() to match "team" object saved on user model)
    let teamOptions = teamsInfo === null ? [] : teamsInfo
        .filter(row => gender ? row.gender === gender.value : true)
        .filter(row => conference ? row.conferenceId === conference.value : true)
        .filter(row => team && row.teamId === team.value ? false : true)
        .sort((a, b) => a.teamMarket < b.teamMarket ? -1 : 1)
        .map(row => {
            return {
                value: row.teamId,
                label: `${row.teamMarket} ${row.teamName}`,
                teamId: row.teamId,
                teamMarket: row.teamMarket,
                teamName: row.teamName,
                gender: row.gender,
                conferenceId: row.conferenceId,
                divisionId: row.divisionId
            };
        });

    const teamSelect =
        (<Select
            className='cbb-select'
            styles={signupSelectStyles}
            placeholder={'Select Team'}
            value={team}
            onChange={setTeam}
            options={teamOptions}
            isDisabled={conferenceOptions.length === 0 || gender === null || noChangesRemaining || isLoading}
        />);

    const playerPctilesTypeSelect1 =
        (<CBBSelect
            selectType='playerPctileTypes'
            stylesType='signup'
            value={playerPctilesType}
            setValue={setPlayerPctilesType}
        />);

    const shotChartColorsSelect =
        (<CBBSelect
            selectType='shotChartColors'
            stylesType='signup'
            value={shotChartColors}
            setValue={setShotChartColors}
        />);

    // Submit Button
    let isFirstNameValid = typeof (firstName) !== 'undefined' && firstName.length >= 2;
    let isLastNameValid = typeof (lastName) !== 'undefined' && lastName.length >= 2;
    let isSubmitDisabled = !(isFirstNameValid && isLastNameValid && team && gender && conference);
    const submitButton =
        (<div
            className={`cbb-submit-button ${isSubmitDisabled ? 'disabled' : ''} ${showAlert ? 'faded' : ''}`}
            style={{ width: 225, textAlign: 'center' }}
            onClick={handleUpdates}
        >
            {`${isUpdating ? 'Profile Updating...' : 'Submit Updated Profile'}`}
        </div>);

    // Logout Button
    const navigate = useNavigate();
    const handleLogout = () => {
        // eslint-disable-next-line no-undefined
        setUserData({ token: undefined, user: undefined });
        localStorage.setItem('auth-token', '');
        navigate('/');
    };
    const logoutButton =
        (<div
            className={`cbb-submit-button dark-red ${showAlert ? 'faded' : ''}`}
            style={{ width: 225, textAlign: 'center' }}
            onClick={() => {
                // eslint-disable-next-line no-alert
                if (window.confirm('Are you sure? To log back in, you will need your account password.')) {
                    handleLogout();
                }
            }}
        >
            {`${isUpdating ? 'Logging Out' : 'Log Out'}`}
        </div>);

    // Team & Conference Logos
    const teamLogo =
        (<div style={{ marginLeft: 12 }}>
            <Logo
                className='logo-sticker'
                style={{ width: 60, height: 60, display: 'flex', alignItems: 'center' }}
                ptgc='team'
                teamId={team ? team.value : null}
            />
        </div>);
    const confLogo =
        (<Logo
            className='logo-sticker'
            style={{ width: 60, maxHeight: 70, display: 'flex', alignItems: 'center' }}
            ptgc='conference'
            conferenceId={conference ? conference.value : null}
        />);

    const alertStyles = showAlert ? {} : { opacity: 0, visibility: 'hidden', pointerEvents: 'none' };
    const successAlert =
        (<Alert
            className={`modal-alert ${showAlert ? 'animate-fade-away' : ''}`}
            style={{ marginTop: 5, width: 225, textAlign: 'center', ...alertStyles }}
            variant='success'
            onAnimationEnd={() => setShowAlert(false)}
        >
            Your profile was updated
        </Alert>);
    // ========

    // Shared Section Styles (if changed, change across all views)
    let sectionRowStyles = {
        borderBottom: '2px solid #222222',
        margin: 0,
        paddingTop: 8,
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    };

    // and return
    return (<>
        <Row style={{ ...sectionRowStyles }}>
            <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                {email ? `Update User Profile, ${email}` : 'Update User Profile'}
            </h3>
        </Row>
        <Row style={{ position: 'absolute', top: '-25px', right: '30px' }}>
            <div style={{ display: 'flex' }}>
                {conference && confLogo}
                {team && teamLogo}
            </div>
        </Row>
        <Row style={{ marginTop: 8, justifyContent: 'center' }}>
            <Col xs={12} sm={6} style={{ maxWidth: 375 }}>
                <h3 className='select-header' style={{ marginTop: 8 }}>First Name</h3>
                {firstNameFormControl}
                {!isFirstNameValid && <h3 className='form-error-header'>...first name too short</h3>}

                <h3 className='select-header' style={{ marginTop: 8 }}>Last Name</h3>
                {lastNameFormControl}
                {!isLastNameValid && <h3 className='form-error-header'>...last name too short</h3>}

                <h3 className='select-header' style={{ marginTop: 8 }}>Player Percentiles</h3>
                {/* {playerPctilesTypeSelect} */}
                {playerPctilesTypeSelect1}

                <h3 className='select-header' style={{ marginTop: 8 }}>Shot Chart Colors</h3>
                {shotChartColorsSelect}
            </Col>
            <Col xs={12} sm={6} style={{ maxWidth: 375 }}>
                <h3 className='select-header' style={{ marginTop: 8 }}>Default League</h3>
                {leagueSelect}
                {!gender && <h3 className='form-error-header'>...select a league</h3>}

                <h3 className='select-header' style={{ marginTop: 8 }}>Default Conference</h3>
                {conferenceSelect}
                {!conference && <h3 className='form-error-header'>...select a conference</h3>}

                <h3 className='select-header' style={{ marginTop: 8 }}>Default Team</h3>
                {teamSelect}
                {!team && <h3 className='form-error-header'>...select a team</h3>}
            </Col>
        </Row>
        {userTier.value <= 1 &&
            <Row style={{ marginLeft: 0 }}>
                <p style={{ marginTop: 15 }}>
                    Trial accounts are limited to changing the default team on the account a maximum number of times.
                    This account has <span style={{ fontWeight: 700, color: '#0066CC' }}>{teamChangesRemaining} team update remaining.</span>
                </p>
            </Row>
        }
        <Row style={{ marginTop: 30, justifyContent: 'center' }}>
            {submitButton}
        </Row>
        <Row style={{ marginTop: 5, justifyContent: 'center' }}>
            {logoutButton}
        </Row>

        {/* Alerts Below Sections */}
        <Row style={{ justifyContent: 'center' }}>
            {successAlert}
        </Row>
    </>);
}

export default MyAccountSettings;
