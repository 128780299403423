
import React, { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Row } from 'react-bootstrap';

// Links not all valid right now
const premiumPages = (competitionId, teamId) => {
    const pagesObj = {
        // Division Pages
        'division-team-box': { suffix: 'team-box', title: 'Division Stats - Team Box Stats', link: '' },
        'division-team-pbp': { suffix: 'team-pbp', title: 'Division Stats - Team PBP Stats', link: '' },
        'division-team-shooting': { suffix: 'team-shooting', title: 'Team Shooting', link: '' },
        'division-by-start-and-length': { suffix: 'by-start-and-length', title: 'Division By Start & Length', link: `` },
        'division-player-box': { suffix: 'player-box', title: 'Box Score Player Stats', link: '' },
        'division-player-pbp': { suffix: 'player-pbp', title: 'Play-By-Play Player Stats', link: '' },
        'division-player-shooting': { suffix: 'player-shooting', title: 'Player Shooting', link: '' },
        'division-player-profiles': { suffix: 'player-profiles', title: 'Player Profiles', link: '' },
        'division-game-recaps': { suffix: 'game-recaps', title: 'Game Recaps', link: '' },
        'division-lineups': { suffix: 'lineups', title: 'Lineups', link: '' },
        'division-on-off': { suffix: 'on-off', title: 'On/Off', link: '' },
        // Game Pages
        'game-overview': { suffix: 'overview', title: 'Game Overview', link: '/stats/27694/games/1864019/overview' },
        'game-team-pbp': { suffix: 'team-pbp', title: 'Game Team PBP', link: '/stats/27694/games/1864019/team-pbp' },
        'game-overview-bottom': { suffix: 'overview-bottom', title: 'Game Overview', link: '/stats/27694/games/1864019/overview' },
        'game-player-box': { suffix: 'player-box', title: 'Game Player Stats', link: '/stats/27694/games/1864019/player-box' },
        'game-player-pbp': { suffix: 'player-pbp', title: 'Game Player PBP', link: '/stats/27694/games/1864019/player-pbp' },
        'game-lineup-flow': { suffix: 'lineup-flow', title: 'Game Lineup Flow', link: '/stats/27694/games/1864019/lineup-flow' },
        // Team Pages
        'team-overview': { suffix: 'overview', title: 'Team Overview', link: `/stats/${competitionId}/teams/${teamId}/overview` },
        'team-shooting': { suffix: 'shooting', title: 'Team Shooting', link: `/stats/${competitionId}/teams/${teamId}/shooting` },
        'team-pbp-stats': { suffix: 'pbp-stats', title: 'Team PBP Stats', link: `/stats/${competitionId}/teams/${teamId}/pbp-stats` },
        'team-by-start-and-length': { suffix: 'by-start-and-length', title: 'Team By Start & Length', link: `/stats/${competitionId}/teams/${teamId}/by-start-and-length` },
        'team-player-box': { suffix: 'player-box', title: 'Team Player Stats', link: `/stats/${competitionId}/teams/${teamId}/player-box` },
        'team-player-pbp': { suffix: 'player-pbp', title: 'Team Player PBP', link: `/stats/${competitionId}/teams/${teamId}/player-pbp` },
        'team-player-profiles': { suffix: 'player-profile', title: 'Team Player Profiles', link: `/stats/${competitionId}/teams/${teamId}/player-profiles` },
        'team-game-recaps': { suffix: 'game-recaps', title: 'Team Game Recaps', link: `/stats/${competitionId}/teams/${teamId}/game-recaps` },
        'team-lineups': { suffix: 'lineups', title: 'Team Lineups', link: `/stats/${competitionId}/teams/${teamId}/lineups` },
        'team-combos': { suffix: 'combos', title: 'Team Combos', link: `/stats/${competitionId}/teams/${teamId}/combos` },
        'team-on-off': { suffix: 'on-off', title: 'Team On/Off', link: `/stats/${competitionId}/teams/${teamId}/on-off` },
        'team-teammates': { suffix: 'teammates', title: 'Teammates', link: `/stats/${competitionId}/teams/${teamId}/teammates` },
        // Player Pages
        'player-overview': { suffix: 'overview', title: 'Player Overview', link: '' },
        'player-shooting': { suffix: 'shooting', title: 'Player Shooting', link: '' },
        'player-compare': { suffix: 'compare', title: 'Player Comparison', link: '' },
        'player-game-recaps': { suffix: 'game-recaps', title: 'Player Game Recaps', link: '' },
        'player-on-off': { suffix: 'on-off', title: 'Player On/Off', link: '' },
        'player-comps': { suffix: 'comps', title: 'Player Comps', link: '' },
        // Conference Pages
        'conference-team-box': { suffix: 'team-box', title: 'Conference Team Box Stats', link: `` },
        'conference-team-pbp': { suffix: 'team-pbp', title: 'Conference Team PBP Stats', link: `` },
        'conference-team-shooting': { suffix: 'team-shooting', title: 'Conference Team Shooting', link: `` },
        'conference-by-start-and-length': { suffix: 'by-start-and-length', title: 'Conference By Start & Length', link: `` },
        'conference-player-box': { suffix: 'player-box', title: 'Conference Player Box Stats', link: `` },
        'conference-player-pbp': { suffix: 'player-pbp', title: 'Conference Player PBP Stats', link: `` },
        'conference-player-shooting': { suffix: 'player-shooting', title: 'Conference Player Shooting', link: `` },
        'conference-game-recaps': { suffix: 'game-recaps', title: 'Conference Game Recaps', link: `` },
        'conference-lineups': { suffix: 'lineups', title: 'Conference Lineup Stats', link: `` },
        // Tourney Pages
        'tourney-team-box': { suffix: 'team-box', title: 'Tournament Team Stats', link: `` },
        'tourney-team-pbp': { suffix: 'team-pbp', title: 'Tournament Team PBP', link: `` },
        'tourney-team-shooting': { suffix: 'team-shooting', title: 'Tournament Team Shooting', link: `` },
        'tourney-player-box': { suffix: 'player-box', title: 'Tournament Player Stats', link: `` },
        'tourney-player-pbp': { suffix: 'player-pbp', title: 'Tournament Player PBP', link: `` },
        'tourney-player-shooting': { suffix: 'player-shooting', title: 'Tournament Player Shooting', link: `` },
        'tourney-game-recaps': { suffix: 'game-recaps', title: 'Tournament Game Recaps', link: `` },
        'tourney-lineups': { suffix: 'lineups', title: 'Tournament Lineup Stats', link: `` },
        'tourney-bracket': { suffix: 'bracket', title: 'Tournament Bracket', link: `` },
        // User Tier, Pro Tier Required Pages
        'team-user-tier': { suffix: 'user-tier', title: 'Team', link: '' },
        'team-pro-tier': { suffix: 'pro-tier', title: 'Team', link: '' },
        'player-user-tier': { suffix: 'user-tier', title: 'Player', link: '' },
        'player-pro-tier': { suffix: 'pro-tier', title: 'Player', link: '' },
        'conference-user-tier': { suffix: 'user-tier', title: 'Conference', link: '' },
        'conference-pro-tier': { suffix: 'pro-tier', title: 'Conference', link: '' },
        'game-user-tier': { suffix: 'user-tier', title: 'Game', link: '' },
        'game-pro-tier': { suffix: 'pro-tier', title: 'Game', link: '' },
        'division-user-tier': { suffix: 'user-tier', title: 'Division', link: '' },
        'division-pro-tier': { suffix: 'pro-tier', title: 'Division', link: '' },
        // Transfer Sections
        'portal-pages': { suffix: 'na', title: 'Transfer Portal Analytics', link: '' },
        'transfer-pages': { suffix: 'na', title: 'Transfer Portal Analytics', link: '' },
        // Tools Pages
        'comparisons': { suffix: 'na', title: 'Comparisons App', link: '' },
        'model-fitting': { suffix: 'na', title: 'Regressions App', link: '' },
        'bar-charts': { suffix: 'na', title: 'Bar Charts App', link: '' },
        'player-ratings': { suffix: 'na', title: 'Player Ratings App', link: '' },
        'streaming': { suffix: 'streaming', title: 'Streaming Dashboard', link: '' },
        'nil-predictions': { suffix: 'na', title: 'NIL Predictions', link: '' }
    };

    return pagesObj;
};

function NotSubscribed({
    errorType = 'notSubscribed',
    page = 'game-lineup-flow',
    config = {}
}) {
    // console.log('Not Subscribed Params: ', { errorType, page, config });

    // useContext, useNavigate
    const { setShowLogin, userData, defaultCompetition, trialPlayers } = useContext(GlobalContext);
    const navigate = useNavigate();

    // safe-extract user variables
    const isLoggedIn = userData.token ? true : false;
    const defaultTeamMarket = isLoggedIn ? userData.user.team.teamMarket : '';
    const defaultTeamName = isLoggedIn ? userData.user.team.teamName : '';
    const defaultTeamId = isLoggedIn ? userData.user.team.teamId : '';
    const defaultGender = isLoggedIn ? userData.user.gender.value : '';
    const genderText = defaultGender === 'MALE' ? 'MBB' : 'WBB';
    const linkCompetitionId = defaultCompetition.value;
    const pagesObj = premiumPages(linkCompetitionId, defaultTeamId);
    const pagePrefix = page.slice(0, 4);

    const pageSuffix = pagesObj[page]?.suffix || '';
    const pageTitle = pagesObj[page]?.title || '';
    const pageLink = pagesObj[page]?.link || '';

    // Pro Tier Demo Links
    // ====================
    const mbbGamePrefix = `/stats/36046/games/2427594`;
    const mbbFinalGameLink =
        (<Link target='_blank' to={`${mbbGamePrefix}/overview`} style={{ color: '#0066CC' }}>
            UConn vs. Purdue: 2024 MBB NCAA Championship Game
        </Link>);
    const wbbGameUrl = `/stats/36045/games/2427659/overview`;
    const wbbFinalGameLink =
        (<Link target='_blank' to={wbbGameUrl} style={{ color: '#0066CC' }}>
            South Carolina vs Iowa: 2024 WBB NCAA Championship Game
        </Link>);
    const demoPageLink =
        (<Link target='_blank' to={pageLink} style={{ color: '#0066CC' }}>
            demo of our {pageTitle} page
        </Link>);

    // Demo Player Links
    const trialPlayerLinks = trialPlayers.map((row, idx) => {
        const linkPrefix = `/stats/${row.competitionId}/players/${row.playerId}`;
        const linkText = `${idx === trialPlayers.length - 1 ? 'and ' : ''}${row.fullName}${idx === trialPlayers.length - 1 ? '' : ', '}`;
        return (
            <Link
                key={`${row.playerId}-trial-link`}
                target='_blank' // open in new tab
                to={`${linkPrefix}/${pageSuffix}`}
                style={{ color: '#0066CC' }}
            >
                {linkText}
            </Link>);
    });

    const edeyPrefix = `/stats/36046/players/${1606325}`;
    const greatAmerPrefix = `/stats/38409/conferences/${100}`;
    const greatAmerConferenceLink =
        (<Link target='_blank' to={`${greatAmerPrefix}/${pageSuffix}`} style={{ color: '#0066CC' }}>
            Great American Conference
        </Link>);
    const subscribeToUserTierLink =
        (<Link to={`/my-account/subscribe`} style={{ color: '#0066CC' }}>
            User Tier Subscriptions
        </Link>);
    // ========

    // Signup, Login Buttons
    // ========================
    const signupButton =
        (<span className='cbb-span' onClick={() => navigate('/signup')}>
            you can sign up here
        </span>);
    const loginButton =
        (<span className='cbb-span' onClick={() => setShowLogin(true)}>
            log in to get started
        </span>);
    // ========

    const location = useLocation();

    // Email Links
    let nickEmailLink = <a style={{ color: '#0066CC' }} href={`mailto:nick@cbbanalytics.com?subject=Inquiring on CBB Analytics Pro Tier`}>nick@cbbanalytics.com</a>;
    let nickEmailLink2 = <a style={{ color: '#0066CC' }} href={`mailto:nick@cbbanalytics.com?subject=Question on CBB Analytics`}>nick@cbbanalytics.com</a>;

    // Messages
    let proTierNeededP = <p className='header-text'>Pro Tier subscriptions are required to access our top stats package for Division-I. Pro Tier subscriptions are primarily utilized by Division-I coaching staffs, NBA and WNBA front offices, Sports Agencies and National Media Companies. You can email {nickEmailLink} to receive a quote.</p>;
    let subscribeToUserTierP = <p className='header-text'>{subscribeToUserTierLink} are required to access our basic D-I stats package and our full D-II stats package.</p>;

    // And Return
    return (
        <Row className='padded-inner-container' style={{ margin: '0 auto', marginTop: 20 }}>
            <div className='not-subscribed-box'>

                {/* Needs Tier 1: user needs to be logged in, with email verified */}
                {errorType === 'needsTier1' && <>
                    <p className='header-text'>
                        If you already have an account, {loginButton}. If you do not have an account, {signupButton}! For help with any questions, please
                        email {nickEmailLink2}.
                    </p>
                </>}

                {/* Needs Tier 2: User Tier subscription is required */}
                {errorType === 'needsTier2' && !['user-tier', 'pro-tier'].includes(pageSuffix) && <>
                    <p className='header-text'>
                        {subscribeToUserTierLink} are required to access our basic D-I stats package and our full D-II stats package, which includes access to this {pageTitle} page.
                    </p>
                    {pagePrefix === 'team' &&
                        <p className='header-text'>
                            A {demoPageLink} is available for {defaultTeamMarket} {defaultTeamName} {genderText}, the default team currently set for this account.
                        </p>
                    }
                    {pagePrefix === 'play' &&
                        <p className='header-text'>
                            Demo pages available for{` `}
                            {trialPlayerLinks.map((link, idx) => {
                                return (<React.Fragment key={`link-${idx}`}>
                                    {![0, trialPlayerLinks.length - 1].includes(idx) && <span>,{` `}</span>}
                                    {idx === trialPlayerLinks.length - 1 && <span>, and </span>}
                                    {link}
                                </React.Fragment>);
                            })}.
                        </p>
                    }
                    {pagePrefix === 'conf' &&
                        <p className='header-text'>
                            Demo pages available for {greatAmerConferenceLink}.
                        </p>
                    }
                    {pagePrefix === 'game' && <>
                        <p className='body-text'>
                            Demo pages available for the {mbbFinalGameLink} and for the {wbbFinalGameLink}.
                        </p>
                    </>}
                </>}

                {/* ==== User-Tier & Pro-Tier Access to Team Pages ==== */}
                {pageSuffix === 'user-tier' && pagePrefix === 'team' && <>
                    <p className='header-text'>
                         The following
                        team pages are featured in our User Tier stats package for D-I teams:
                    </p>
                    <ul>
                        <li><Link target='_blank' to={pagesObj['team-overview'].link} style={{ color: '#0066CC' }}>Team Overview pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-shooting'].link} style={{ color: '#0066CC' }}>Team Shooting pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-player-box'].link} style={{ color: '#0066CC' }}>Team Player Stats pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-player-pbp'].link} style={{ color: '#0066CC' }}>Team Player PBP pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-game-recaps'].link} style={{ color: '#0066CC' }}>Team Game Recaps pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-lineups'].link} style={{ color: '#0066CC' }}>Team Lineups pages</Link></li>
                    </ul>
                </>}
                {pageSuffix === 'pro-tier' && pagePrefix === 'team' && <>
                    {proTierNeededP}
                    <p className='header-text'>In addition to all pages included in our User Tier, the following D-I team pages are featured in our Pro Tier stats package:</p>
                    <ul>
                        <li><Link target='_blank' to={pagesObj['team-pbp-stats'].link} style={{ color: '#0066CC' }}>Team PBP Stats pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-by-start-and-length'].link} style={{ color: '#0066CC' }}>Team By Start & Length pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-player-profiles'].link} style={{ color: '#0066CC' }}>Team Player Profiles pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-combos'].link} style={{ color: '#0066CC' }}>Team Combos pages</Link></li>
                        <li><Link target='_blank' to={pagesObj['team-on-off'].link} style={{ color: '#0066CC' }}>Team On/Off pages</Link></li>
                    </ul>
                </>}

                {/* ==== User-Tier & Pro-Tier Access to Player Pages ==== */}
                {pageSuffix === 'user-tier' && pagePrefix === 'play' && <>
                    {subscribeToUserTierP}
                    <p className='header-text'>The following player pages are featured in our User Tier stats package for D-I players:</p>
                    <ul>
                        <li><Link target='_blank' to={`${edeyPrefix}/overview`} style={{ color: '#0066CC' }}>Player Overview pages</Link></li>
                        <li><Link target='_blank' to={`${edeyPrefix}/game-recaps`} style={{ color: '#0066CC' }}>Player Game Recaps pages</Link></li>
                        <li><Link target='_blank' to={`${edeyPrefix}/comps`} style={{ color: '#0066CC' }}>Player Comps pages</Link></li>
                    </ul>
                </>}

                {pageSuffix === 'pro-tier' && pagePrefix === 'play' && <>
                    {proTierNeededP}
                    <p className='header-text'>In addition to all pages included in our User Tier, the following D-I player pages are featured in our Pro Tier stats package:</p>
                    <ul>
                        <li><Link target='_blank' to={`${edeyPrefix}/shooting`} style={{ color: '#0066CC' }}>Player Shooting pages</Link></li>
                        {/* <li><Link target='_blank' to={`${edeyPrefix}/on-off`} style={{ color: '#0066CC' }}>Player On/Off pages</Link></li> */}
                        <li><Link target='_blank' to={`${edeyPrefix}/career`} style={{ color: '#0066CC' }}>Player Career pages</Link></li>
                    </ul>
                </>}

                {/* ==== User-Tier & Pro-Tier Access to Conference Pages ==== */}
                {pageSuffix === 'user-tier' && pagePrefix === 'conf' && <>
                    {subscribeToUserTierP}
                    <p className='header-text'>The following conference pages are featured in our User Tier stats package for D-I conferences:</p>
                    <ul>
                        <li><Link target='_blank' to={`${greatAmerPrefix}/team-box`} style={{ color: '#0066CC' }}>Conference Team Stats pages</Link></li>
                        <li><Link target='_blank' to={`${greatAmerPrefix}/player-box`} style={{ color: '#0066CC' }}>Conference Player Stats Pages</Link></li>
                        <li><Link target='_blank' to={`${greatAmerPrefix}/game-recaps`} style={{ color: '#0066CC' }}>Conference Game Recaps pages</Link></li>
                    </ul>
                </>}
                {pageSuffix === 'pro-tier' && pagePrefix === 'conf' && <>
                    {proTierNeededP}
                    <p className='header-text'>In addition to all pages included in our User Tier, the following D-I conference pages are featured in our Pro Tier stats package:</p>
                    <ul>
                        <li><Link target='_blank' to={`${greatAmerPrefix}/team-pbp`} style={{ color: '#0066CC' }}>Conference Team PBP pages</Link></li>
                        <li><Link target='_blank' to={`${greatAmerPrefix}/by-start-and-length`} style={{ color: '#0066CC' }}>Conference By Start & Length pages</Link></li>
                        <li><Link target='_blank' to={`${greatAmerPrefix}/player-pbp`} style={{ color: '#0066CC' }}>Conference Player PBP pages</Link></li>
                        <li><Link target='_blank' to={`${greatAmerPrefix}/lineups`} style={{ color: '#0066CC' }}>Conference Lineups pages</Link></li>
                    </ul>
                </>}

                {/* ==== User-Tier & Pro-Tier Access to Game Pages ==== */}
                {pageSuffix === 'user-tier' && pagePrefix === 'game' && <>
                    {subscribeToUserTierP}
                    <p className='header-text'>The following game pages are featured in our User Tier stats package for D-I games:</p>
                    <ul>
                        <li><Link target='_blank' to={`${mbbGamePrefix}/overview`} style={{ color: '#0066CC' }}>Game Overview pages</Link></li>
                        <li><Link target='_blank' to={`${mbbGamePrefix}/player-box`} style={{ color: '#0066CC' }}>Game Player Boxscore Stats pages</Link></li>
                    </ul>
                </>}
                {pageSuffix === 'pro-tier' && pagePrefix === 'game' && <>
                    {proTierNeededP}
                    <p className='header-text'>In addition to all pages included in our User Tier, the following D-I game pages are featured in our Pro Tier stats package:</p>
                    <ul>
                        <li><Link target='_blank' to={`${mbbGamePrefix}/player-pbp`} style={{ color: '#0066CC' }}>Game Player PBP Pages</Link></li>
                        <li><Link target='_blank' to={`${mbbGamePrefix}/lineup-flow`} style={{ color: '#0066CC' }}>Game Lineup Flow pages</Link></li>
                    </ul>
                </>}

                {/* ==== User-Tier & Pro-Tier Access to Division Pages ==== */}
                {pageSuffix === 'user-tier' && pagePrefix === 'divi' && <>
                    {subscribeToUserTierP}
                    <p className='header-text'>The following division pages are featured in our User Tier stats package for D-I:</p>
                    <ul>
                        <li>Division Team Stats pages</li>
                        <li>Division Player Stats pages</li>
                        <li>Division Game Recaps pages</li>
                    </ul>
                </>}
                {pageSuffix === 'pro-tier' && pagePrefix === 'divi' && <>
                    {proTierNeededP}
                    <p className='header-text'>In addition to all pages included in our User Tier, the following D-I division pages are featured in our Pro Tier stats package:</p>
                    <ul>
                        <li>Division Team PBP pages</li>
                        <li>Division Team Shooting pages</li>
                        <li>Division Player PBP pages</li>
                        <li>Division Player Shooting pages</li>
                        <li>Division Player Profiles pages</li>
                        <li>Division Lineups pages</li>
                        <li>Division On/Off pages</li>
                    </ul>
                </>}


                {/* Needs Tier 3+: Pro Tier subscription is required */}
                {errorType === 'needsTier3' && !['user-tier', 'pro-tier'].includes(pageSuffix) && <>
                    <p className='header-text'>
                        Pro Tier subscriptions are required to access our top stats package, which includes access to this {pageTitle} page. Pro Tier subscriptions are primarily utilized by Division-I coaching staffs, NBA and WNBA front offices, Sports Agencies and National Media Companies. You can email {nickEmailLink} to receive a quote.
                    </p>
                    {pagePrefix === 'game' && <>
                        <span className='body-text'>
                            Demo pages available for the {mbbFinalGameLink} and for the {wbbFinalGameLink}.
                        </span>
                    </>}
                    {pagePrefix === 'play' &&
                        <span className='body-text'>
                            Demo pages available for {trialPlayerLinks}.
                        </span>
                    }
                    {pagePrefix === 'conf' &&
                        <span className='body-text'>
                            Demo pages available for {greatAmerConferenceLink}.
                        </span>
                    }
                    {pagePrefix === 'team' && <>
                        <span className='body-text'>
                            A {demoPageLink} is available for {defaultTeamMarket} {defaultTeamName} {genderText}, the default team currently set for this account.
                        </span>
                    </>}
                </>}

                {/* Exhibition Not available */}
                {errorType === 'exhibNotAvailable' && <>
                    <p className='header-text'>
                        Stats from exhibition games are not available
                    </p>
                    <span className='body-text'>
                        Game statistics are not currently available for exhibition games. The statistics accumulated in exhibition games to not count towards season-long statistical averages, and exhibition games are not considered when computing percentiles for other game statistics.
                    </span>
                </>}

                {/* Bad Game Status (cancelled, postponed, etc.) */}
                {errorType === 'badGameStatus' && <>
                    <p className='header-text'>
                        Game marked as <strong>{config.gameStatus}</strong>
                    </p>
                    <span className='body-text'>
                        Game statistics are not available for games marked as <strong>{config.gameStatus}</strong>. Please email info@cbbanalytics.com if this game has been completed, or is scheduled to be played, and you believe the game is erroneously marked as <strong>{config.gameStatus}</strong>.
                    </span>
                </>}

                {/* MISSINGBOX flagged in DBT due to issue (box score data missing, etc) with the raw data */}
                {errorType === 'gameMissingBox' && <>
                    <p className='header-text'>
                        Issue with the raw box score data for this game
                    </p>
                    <span className='body-text'>
                        Most likely, the issue here is that the raw box score data was not properly submitted to the NCAA after the game was completed. Please email {nickEmailLink2} if this game has been completed, and you believe the box score data should be available.
                    </span>
                </>}

                {/* The GameId and CompetitionId are both valid, but gameId doesn't belong to competitionId */}
                {errorType === 'gameIdInOtherCompetitionId' && <>
                    <p className='header-text'>
                        Wrong Competition ID for Game: <strong>{config.gameId}</strong>
                    </p>
                    <span className='body-text'>
                        <Link style={{ paddingRight: 5 }} to={location.pathname.replace(config.badId, config.goodId)}>
                            Click here
                        </Link>
                        to redirect to the correct URL associated with this game ID. Please email info@cbbanalytics.com if a broken link or button brought you to this page.
                    </span>
                </>}

                {/* No Games Played: Error Handling for Start of Season, Cancelled Seasons */}
                {errorType === 'noGamesPlayed' && <>
                    <p className='header-text'>
                        There are no stats available for this {config.entity} for this season. Select a different {config.entity} or season to see available stats.
                    </p>
                </>}
            </div>
        </Row>
    );
}

export default NotSubscribed;
