
// logic for URL:
    // if redirecting from settings -> subscribe, we redirect to the subscript tier a user is on
    // if no subscription tier, we redirect to the user tier
    // if explicit going to tier, stay with that tier (regardless of what they are on)

// Import React Components
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Axios from 'axios';

// Import Data Fetching Stuff
import config from '../../../config.js';
import GlobalContext from '../../../context/GlobalContext.js';

// Import CBB Components
import CBBSelect from '../../../components/selects/CBBSelect.js';

// Import Stripe Components
import CheckoutFormWrapper from '../stripe/CheckoutFormWrapper.js';
import StartSubscriptionPanel from '../stripe/buttons/StartSubscriptionPanel.js';
import AutoRenewSubscriptionButton from '../../../components/ui/buttons/AutoRenewSubscriptionButton.js';


const getTrialTimeRemaining = (trialExpiresAt) => {
    // Trial Time Remaining
    let now = new Date();
    let zed = new Date(trialExpiresAt);
    let minsRemaining = ((zed - now) / (1000 * 60));
    let diffValue, diffUnits;
    if (minsRemaining / 1440 >= 2) {
        diffValue = Math.floor(minsRemaining / 1440); diffUnits = 'day';
    } else if (minsRemaining / 60 >= 1) {
        diffValue = Math.floor(minsRemaining / 60); diffUnits = 'hour';
    } else { diffValue = Math.floor(minsRemaining); diffUnits = 'minute'; }
    let diffText = diffValue === 1 ? `is ${diffValue} ${diffUnits}` : `are ${diffValue} ${diffUnits}s`;
    return diffText;
};

const priceDict = {
    30: { value: 30, label: '$30 / Month', text: '$30 / month (billed monthly)' },
    85: { value: 85, label: '$85 / 3 Months', text: '$85 / 3 months (billed once every 3 months)' },
    160: { value: 160, label: '$160 / 6 Months', text: '$160 / 6 months (billed once every 6 months)' },
    299: { value: 299, label: '$299 / 12 Months', text: '$299 / 12 months (billed once every 12 months)' },
    60: { value: 60, label: '$60 / Month', text: '$60 / month (billed monthly)' },
    600: { value: 600, label: '$600 / 12 Months', text: '$600 / 12 months (billed once every 12 months)' }
};

// Create The Component
function MyAccountSubscribe() {
    // useContext: Grab User Info
    const { userTier, userData } = useContext(GlobalContext);
    const canSubToProTier = userData?.user?.payments?.canSubToProTier || false;
    const trialExpiresAt = userData.user ? userData.user.trialExpiresAt : null;
    const isActiveTrial = trialExpiresAt !== null;

    // useState: set page state
    const [upgradeToTier, setUpgradeToTier] = useState(() => {
        const pathParts = window.location.pathname.split('/');
        const tierPath = pathParts[pathParts.length - 1];
        return tierPath === 'pro-tier' ? 'Pro Tier' : 'User Tier';
    });
    const [changePlanTier, setChangePlanTier] = useState(null);
    const [price, setPrice] = useState(null);
    const [isPriceUpdating, setIsPriceUpdating] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [updatePlanAlert, setUpdatePlanAlert] = useState(null);
    // console.log('STATE: ', {
    //     upgradeToTier, changePlanTier, price, isPriceUpdating, paymentMethod, subscription, updatePlanAlert
    // });

    // constants from state
    const currentPlanAmount = subscription && subscription.plan && subscription.plan.amount ? subscription.plan.amount / 100 : null;
    // console.log('subscription: ', subscription);

    const navigate = useNavigate();
    const handleTierChange = (tier) => {
        // the main tier change for select
        setUpgradeToTier(tier);

        // update path
        const tierPath = tier.toLowerCase().replace(/\s+/g, '-');
        navigate(`/my-account/subscribe/${tierPath}`);
    };

    // Get user's default Stripe payment method
    const setSubscriptionInfo = async () => {
        try {
            const apiBaseUrl = config.url.API_URL;
            const authHeader = { headers: { 'x-auth-token': userData.token } };
            const response = await Axios.get(`${apiBaseUrl}/stripe/get-payment-method`, authHeader);

            const hasCard = response?.data?.hasPaymentMethod || false;
            const planPrice = response?.data?.subscription?.plan?.amount / 100 || null;
            const isPlanProTier = [60, 600].includes(planPrice);

            // console.log('get-payment-method response: ', { data: response.data, hasCard, planPrice, isPlanProTier });

            // set payment method and subscription
            setPaymentMethod(hasCard ? response.data.paymentMethod : null);
            setSubscription(hasCard ? response.data.subscription : null);

            // Only set tier and price if we're on the base subscribe path
            if (location.pathname === '/my-account/subscribe') {
                setUpgradeToTier(isPlanProTier ? 'Pro Tier' : 'User Tier');
                const targetTier = isPlanProTier ? 'pro-tier' : 'user-tier';
                navigate(`/my-account/subscribe/${targetTier}`, { replace: true });
            }

            // Always set change plan tier and price
            setChangePlanTier(isPlanProTier
                ? { value: 4, label: 'Pro Tier: D-I Teams Full Access' }
                : { value: 2, label: 'User Tier: Fans, Writers, D-II & D-III Teams' }
            );
            setPrice(priceDict[planPrice]);
        } catch (err) {
            console.log('err: ', err);
            // Only redirect on error if we're on base path
            if (location.pathname === '/my-account/subscribe') {
                navigate('/my-account/subscribe/user-tier', { replace: true });
            }
        }
    };

    let handleUpdatePlan = async () => {
        try {
            // update payment schedule in stripe
            setIsPriceUpdating(true);
            const authHeader = { headers: { 'x-auth-token': userData.token } };
            const paymentObject = { subscription, priceKey: price?.value };
            const response = await Axios.post(`${config.url.API_URL}/stripe/update-payment-schedule`, paymentObject, authHeader);

            // if plan changed, need to update the user's tier

            // save updated subscription to state
            setSubscription(response && response.data && response.data.subscription);
            setUpdatePlanAlert({ type: 'success', message: 'Plan updated successfully' });
        } catch (err) {
            console.log('err: ', err);
            setUpdatePlanAlert({ type: 'error', message: 'Failed to update plan. Please try again.' });
        } finally {
            setIsPriceUpdating(false);
            setTimeout(() => setUpdatePlanAlert(null), 15000); // Clear alert after 15 seconds
        }
    };


    // Fetch Payment Method & Payments as needed
    useEffect(() => {
        // if user is not logged in, we don't/can't fetch their payments data.
        if (!userData.token) { return; }
        setSubscriptionInfo();
    }, [userData.token, userTier]);


    // User Tier vs Pro Tier Buttons
    // ==============================
    const tierOptions = ['User Tier', 'Pro Tier'];
    const userTierProTierButtons = (
        <Col xs={12} sm={6}>
            <Row style={{ marginLeft: -1, marginRight: -1 }}>
                {tierOptions.map(d => {
                    return (
                        <Col key={d} xs={6} style={{ width: '100%', paddingRight: 1, paddingLeft: 1 }}>
                            <span
                                key={d}
                                className={`cbb-button ${d === upgradeToTier ? 'selected' : ''} ${isPriceUpdating ? 'disabled' : ''}`}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => handleTierChange(d)}
                            >
                                {d}
                            </span>
                        </Col>
                    );
                })}
            </Row>
        </Col>
    );

    // Button and Select(s) for Updating Plan
    // =======================================
    let changePlanTierSelect =
        (<CBBSelect
            selectType='setUserTier'
            value={changePlanTier}
            setValue={(e) => {
                setChangePlanTier(e);
                setPrice(e.value === 2 ? priceDict[30] : priceDict[60]);
            }}
            stylesType='small'
            isDisabled={isPriceUpdating}
            optionGroup={4}
        />);

    let userTierPriceSelect =
        (<CBBSelect
            selectType='subscriptionPackages'
            value={price}
            setValue={setPrice}
            stylesType='small'
            isDisabled={isPriceUpdating}
            optionGroup='userTier'
        />);

    let proTierPriceSelect =
        (<CBBSelect
            selectType='subscriptionPackages'
            value={price}
            setValue={setPrice}
            stylesType='small'
            isDisabled={isPriceUpdating}
            optionGroup='proTier'
        />);

    let isSamePrice = price?.value === currentPlanAmount;
    let isReady = !isPriceUpdating && !isSamePrice;
    let updatePlanButton =
        (<span
            className={`myaccount-button ${isPriceUpdating || isSamePrice ? 'disabled' : ''} ${isReady ? 'ready' : ''}`}
            style={{ width: 80, fontSize: '0.875em', marginRight: 3, marginTop: 3, paddingTop: 2, cursor: 'pointer' }}
            onClick={() => handleUpdatePlan()}
        >
            {isPriceUpdating ? 'Updating...' : isSamePrice ? 'No Changes' : 'Update Plan'}
        </span>);

    let updatePlanAlertMessage =
        (<div
            className={`alert ${updatePlanAlert?.type === 'success' ? 'alert-success' : 'alert-danger'}`}
            style={{
                marginTop: 3,
                padding: '6px 15px',
                borderRadius: 5,
                animation: `fadeOut 8s forwards`
            }}
        >
            {updatePlanAlert?.message}
        </div>);

    const canChangePlan = subscription && subscription.status !== 'canceled';
    const changePlanPanel = (<>
        <Row style={{ margin: 0, marginTop: 5, paddingTop: 15, fontSize: '0.875em', borderTop: '1px solid #888' }}>
            <p style={{ lineHeight: 1.35 }}>Your current plan charges
                <strong style={{ color: 'darkgreen', paddingLeft: 3 }}>{priceDict[currentPlanAmount]?.text}</strong>
            . You can update your billing cycle at any time, and you will not be charged until your next payment is due. To cancel your account, simply turn off the auto-renew. Your card will no longer be charged, and you will keep your account through the current paid-for period.</p>
        </Row>
        <Row style={{ margin: 0, marginTop: 10 }}>
            {canSubToProTier && changePlanTierSelect}
        </Row>
        <Row style={{ margin: 0 }}>
            {changePlanTier?.value === 2 && userTierPriceSelect}
            {canSubToProTier && changePlanTier?.value === 4 && proTierPriceSelect}
            {updatePlanButton}
        </Row>
        {updatePlanAlert &&
            <Row style={{ margin: 0, marginBottom: 2 }}>
                {updatePlanAlertMessage}
            </Row>
        }
    </>);

    // Email Links
    let nickEmailLink1 = <a style={{ color: '#0066CC' }} href={`mailto:nick@cbbanalytics.com?subject=Questions on User Tier`}>nick@cbbanalytics.com</a>;
    let nickEmailLink2 = <a style={{ color: '#0066CC' }} href={`mailto:nick@cbbanalytics.com?subject=Quote for Pro Tier`}>nick@cbbanalytics.com</a>;

    // other constants
    let showAutoRenewToggleSwitch = subscription && subscription?.status !== 'cancelled'; // do we want to show too cancelled?
    let diffText = getTrialTimeRemaining(trialExpiresAt);
    // console.log('showAutoRenewToggleSwitch:', { showAutoRenewToggleSwitch, subscription, status: subscription?.status });

    // shared section styles
    let sectionRowStyles = {
        borderBottom: '2px solid #222222',
        margin: 0,
        paddingTop: 8,
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    };

    // and return !!
    return (<>
        {/* Top of Section Info */}
        <Row style={{ ...sectionRowStyles }}>
            <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                Account Subscription
            </h3>
        </Row>
        <Row style={{ margin: 0, marginBottom: 8, marginTop: 12 }}>
            <p style={{ lineHeight: 1.3 }}>
                This account is currently signed up for
                <span style={{ marginLeft: 4, marginRight: 4, color: '#0066CC', fontSize: '1.025em', fontWeight: 700 }}>
                    {userTier.label}.
                </span>
                {isActiveTrial && <>
                    This is a trial account, and there
                    <span style={{ marginLeft: 4, marginRight: 4, color: '#0066CC', fontSize: '1.025em', fontWeight: 700 }}>
                        {diffText}
                    </span>
                    remaining on your trial.
                </>}
            </p>
        </Row>

        {/* Payment Tabs Row */}
        <Row style={{ marginBottom: 12 }}>
            {userTierProTierButtons}
        </Row>

        {/* User Tier (Tier 2) Subscriptions */}
        {upgradeToTier === 'User Tier' && <>
            <p style={{ lineHeight: 1.35, marginBottom: 5 }}>
                <strong style={{ color: '#0066CC' }}>User Tier: </strong>
                primarily for fans, writers, sports bettors, and D-II and D-III coaching staffs. User Tier provides full access to
                all D-II and D-III stats, and access to our basic D-I stats package for all teams.
                {[0, 1].includes(userTier.value) && ' All first time User Tier subscriptions come with a free 1-week trial. Email '}
                {[0, 1].includes(userTier.value) && nickEmailLink1}
                {[0, 1].includes(userTier.value) && ' with questions, comments or concerns before subscribing. A subscription is $30 / month, with discounts for longer subscription intervals.'}
            </p>
            <CheckoutFormWrapper>
                <StartSubscriptionPanel
                    toTier='userTier'
                    subscription={subscription}
                    paymentMethod={paymentMethod}
                    style={{ marginTop: 20 }}
                />
            </CheckoutFormWrapper>
            {/* NC: want to show a <loadingspinner  */}
        </>}

        {/* Pro Tier (Tier 4) Subscriptions */}
        {upgradeToTier === 'Pro Tier' && <>
            <p style={{ lineHeight: 1.35, marginBottom: 8 }}>
                <strong style={{ color: '#0066CC' }}>Pro Tier: </strong>
                primarily for D-I coaching staffs, NBA and WNBA scouts and front offices, pro and collegiate agencies, and media companies.
            </p>
            {canSubToProTier &&
                <CheckoutFormWrapper>
                    <StartSubscriptionPanel
                        toTier='proTier'
                        subscription={subscription}
                        paymentMethod={paymentMethod}
                        style={{ marginTop: 20 }}
                    />
                </CheckoutFormWrapper>

            }
            {!canSubToProTier && <>
                <p style={{ lineHeight: 1.35, marginBottom: 5 }}>
                    Reach out to {nickEmailLink2} for a quote. Pro Tier includes full access to the platform, including:
                </p>
                <ul style={{ margin: 0, marginBottom: 20, lineHeight: 1.4 }}>
                    <li>Our full suite of stats for all Division-I teams, players, games</li>
                    <li>Live Stats in real-time as games are happening</li>
                    <li>Access to our Transfer portal Tool and NIL Predictions</li>
                    <li>Post Game Reports available 15 - 30 minutes after games end</li>
                    <li>Ability to download data to excel from all tables on the platform</li>
                    <li>Direct access with Nick and the CBB Analytics team throughout the year</li>
                </ul>
            </>}
        </>}

        {/* Toggle Switch - -Show for Tier 2 (and Admin) Users with non-cancelled subscription */}
        {showAutoRenewToggleSwitch &&
            <AutoRenewSubscriptionButton
                subscription={subscription}
                setParentSubscription={async (e) => setSubscription(e)}
                isDisabled={isPriceUpdating}
                style={{ paddingTop: 8, marginTop: 8, maxWidth: 320, borderTop: '1px solid #888888' }}
            />
        }

        {/* Change Plan Panel (from monthly to 3 month, yearly, etc) */}
        {canChangePlan && changePlanPanel}
    </>);
}

export default MyAccountSubscribe;
