import axios from 'axios';
import { useState } from 'react';
import { allCompetitionIds } from '../harddata/NcaaStructures';
import useDeepCompareEffect from 'use-deep-compare-effect';

// Setup Our Manual Cache for Live Search
// =======================================
let liveSearchCache = { all: {}, player: {}, team: {}, game: {}, conference: {} };
let divisionIds = [0, 1, 2, 3];
allCompetitionIds.all.forEach(competitionId => {
    // cache for competitionId
    liveSearchCache.all[competitionId] = {};
    liveSearchCache.player[competitionId] = {};
    liveSearchCache.team[competitionId] = {};
    liveSearchCache.game[competitionId] = {};
    liveSearchCache.conference[competitionId] = {};

    // cache for competitionId, divisionId
    divisionIds.forEach(divisionId => {
        liveSearchCache.all[competitionId][divisionId] = {};
        liveSearchCache.player[competitionId][divisionId] = {};
        liveSearchCache.team[competitionId][divisionId] = {};
        liveSearchCache.game[competitionId][divisionId] = {};
        liveSearchCache.conference[competitionId][divisionId] = {};
    });
});


// Utility Function to create URL, given config
// ============================================
function createUrl(config) {
    // updated approach using query parameters, new URLSearchParams();

    // Set Base of URL
    let baseUrl = process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_LOCALHOST_NODE_API}/gs/live-search?`
        : `${process.env.REACT_APP_PRODUCTION_NODE_API}/gs/live-search?`;

    // Construct query parameters from config object
    let queryParams = new URLSearchParams();

    // Append parameters as query strings
    if (config.competitionId) { queryParams.append('competition', config.competitionId); }
    if (config.divisionId) { queryParams.append('division', config.divisionId); }
    if (config.ptgc) { queryParams.append('ptgc', config.ptgc); }
    if (config.searchText) { queryParams.append('text', config.searchText); }

    // If Config Is Blank, handle it appropriately (e.g., default search or specific message)
    if (queryParams.toString() === '') { queryParams.append('list', ''); } // You can decide how to handle "no parameters" case.

    // Return full URL
    return baseUrl + queryParams.toString();
}


// Main LiveSearch Function
// ==========================
const useLiveSearchApi = ({ initialValue, cfg }) => {
    // console.log('useLiveSearchApi props: ', { initialValue, config });

    // Set Data-Fetching State
    const [data, setData] = useState(initialValue);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    // Use in lieu of useEffect
    useDeepCompareEffect(() => {
        // Return early if pass is true
        if (cfg.pass) {
            setData([]);
            setIsLoading(false);
            return;
        }

        // Token/Source should be created before "fetchData"
        let source = axios.CancelToken.source();
        let cancel = false;

        // Create Function that makes Axios requests
        const fetchData = async () => {
            // 0) Check for Previous Fetch
            const { competitionId, divisionId, ptgc, searchText } = cfg;
            const prevFetch = competitionId && [0, 1, 2, 3].includes(divisionId)
                ? liveSearchCache[ptgc][competitionId][divisionId][searchText]
                : (competitionId
                    ? liveSearchCache[ptgc][competitionId][searchText]
                    : liveSearchCache[ptgc][searchText]);

            // 1) Load Cached Result if available (with isArray validation)
            if (prevFetch && Array.isArray(prevFetch)) {
                if (cancel) { return; }
                setData(prevFetch);
            }

            // 2) Else, New Fetch Needed
            else {
                // Set Loading
                setIsLoading(true);
                try {
                    // Create + Fetch URL + Result
                    const url = createUrl(cfg);
                    const token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');
                    const response = await axios.get(url, { cancelToken: source.token, headers: { 'x-auth-token': token } });

                    // skip if cancelled
                    if (cancel) { return; }

                    // Validate response data is array
                    const responseData = Array.isArray(response.data) ? response.data : [];
                    setData(responseData);

                    // Only cache valid array data
                    if (Array.isArray(responseData)) {
                        if (competitionId && [0, 1, 2, 3].includes(divisionId)) {
                            liveSearchCache[ptgc][competitionId][divisionId][searchText] = responseData;
                        } else if (competitionId) {
                            liveSearchCache[ptgc][competitionId][searchText] = responseData;
                        } else {
                            liveSearchCache[ptgc][searchText] = responseData;
                        }
                    }
                } catch (error) {
                    if (cancel) { return; }
                    setIsError(true);
                } finally {
                    // eslint-disable-next-line no-unsafe-finally
                    if (cancel) { return; } // this throws eslint 'no-unsafe-finally' warning/error, disallow control flow statements in finally blocks
                    // however, if (cancel) { return; } is needed to avoid "Warning: Can't perform a React state update on an unmounted component" when
                    // exiting components that use LiveSearch, such as the ShotChartsApp. Going to leave in for now as it has always worked just fine.
                    setIsLoading(false);
                    // console.log('hola!');
                }
            }
        };

        // Call Function
        fetchData();

        // Cancel Request if needed in cleanup function
        return () => {
            // console.log('Calling return function to cancel request');
            source.cancel(); // this gets called on keystroke
            cancel = true;
        };
    }, [cfg]);

    // Return as length-3 array
    return [data, isLoading, isError];
};

export default useLiveSearchApi;
