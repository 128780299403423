
// better chunk names when lazy-loading - https://www.belter.io/react-suspense-lazy-chunk-filenames/ - (may not need if using @loadable/component)

// how to use loadable
// import loadable from '@loadable/component';
// const ShotChartsApp = loadable(() => import('./containers/appPages/ShotChartsApp'));

import { lazy } from 'react';

/**
 * Utility function to retry loading chunks to avoid chunk load errors for out of date code
 * @param {Function} componentImport - The import function for the component
 * @returns {Promise} - Promise that resolves with the component
 */
export const lazyRetry = function (componentImport) {
    return new Promise((resolve, reject) => {
        let hasRefreshed = false;
        try {
            // Try to check refresh state
            hasRefreshed = JSON.parse(
                window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
            );
        } catch (err) {
            console.warn('Session storage access failed:', err);
        }

        componentImport().then((component) => {
            try {
                window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
            } catch (err) {
                console.warn('Failed to reset refresh state:', err);
            }
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) {
                try {
                    window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
                } catch (err) {
                    console.warn('Failed to set refresh state:', err);
                }
                return window.location.reload();
            }
            return reject(error);
        });
    });
};


// // NC: not using this. using lazyRetry instead

// lazyWithRetry to avoid ChunkLoadError
//      May not need this if we are using @loadable/component
//      Using Function from here - https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
//      Also a good resource - https://www.codemzy.com/blog/fix-chunkloaderror-react
//      SO Post on this - https://stackoverflow.com/questions/68663106/how-to-solve-chunk-load-error-in-create-react-app-project
export const lazyWithRetry = (componentImport) =>
    // lazyWithPreload(async () => {
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(
                'page-has-been-force-refreshed'
            ) || 'false'
        );

        try {
            const component = await componentImport();
            window.localStorage.setItem(
                'page-has-been-force-refreshed',
                'false'
            );

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
            // Assuming that the user is not on the latest version of the application.
            // Let's refresh the page immediately.
                window.localStorage.setItem(
                    'page-has-been-force-refreshed',
                    'true'
                );
                return window.location.reload();
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
