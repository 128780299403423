import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../config';

const CheckoutFormWrapper = ({ children }) => {
    // useMemo to prevent re-rendering of stripePromise
    const stripePromise = useMemo(() =>
        loadStripe(config.stripePublishableKey),
    [config.stripePublishableKey]);

    // return the Elements component
    return (
        <Elements stripe={stripePromise}>
            {children}
        </Elements>
    );
};

export default CheckoutFormWrapper;
