
// Import React Components
import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'react-bootstrap';

// import View components
import MyAccountSettings from './MyAccountSettings';
import MyAccountOrders from './MyAccountOrders';
import MyAccountUpdateCard from './MyAccountUpdateCard';
import MyAccountResetPassword from './MyAccountResetPassword';
import MyAccountSendReferral from './MyAccountSendReferral';
import MyAccountSubscribe from './MyAccountSubscribe';

// Import Data Fetching Stuff
import GlobalContext from '../../../context/GlobalContext.js';

// Import CBB Utils...
import NotSubscribed from '../../../components/logins/NotSubscribed.js';


// Create The Component
function MyAccount() {
    const params = useParams();
    const navigate = useNavigate();

    const { userData } = useContext(GlobalContext);
    const isLoggedIn = userData.token ? true : false;

    // useState Set Local State
    const [view, setView] = useState(() => { // update initial view state to handle nested subscribe views
        const pathParts = params.view?.split('/') || ['settings'];
        return pathParts[0];
    });


    // Main Section Buttons
    // ====================
    // create the buttons
    const pageNames = ['Settings', 'Subscribe', 'Orders', 'Update Card', 'Password', 'Referral'];
    const pageButtons = pageNames.map(tab => {
        let tabView = tab.toLowerCase().replace(/\s+/g, '-');
        return (
            <Col key={tab} xs={6} sm={2} style={{ padding: 0, width: '100%' }}>
                <span
                    style={{ fontSize: '1.1em', height: 35, padding: 5, marginBottom: 2, width: '100%', display: 'block', border: 'none' }}
                    className={`myaccount-button${tabView === view ? ' selected' : ''}`}
                    onClick={() => {
                        setView(tabView);
                        let newPath = `/my-account/${tabView}`;
                        // Add tier to URL if on subscribe page
                        navigate(newPath);
                    }}
                >
                    {tab}
                </span>
            </Col>
        );
    });

    // early return if not logged in
    if (!isLoggedIn) { return <NotSubscribed errorType='needsTier1' />; }

    return (
        <Container className='my-account-container'>
            <Row className='my-account-button-row'>
                <Row style={{ width: 750, marginRight: 15, marginLeft: 15, justifyContent: 'space-between' }}>
                    {pageButtons}
                </Row>
            </Row>
            <Container className='my-account-inner-container'>

                {/* ====== SECTION 1: User Settings ====== */}
                {view === 'settings' && <MyAccountSettings />}

                {/* ====== SECTION 2: Payments (Subscribe) ====== */}
                {view === 'subscribe' && <MyAccountSubscribe />}

                {/* ====== SECTION 3: Past Orders ====== */}
                {view === 'orders' && <MyAccountOrders />}

                {/* ====== SECTION 4: Update Credit Card ====== */}
                {view === 'update-card' && <MyAccountUpdateCard />}

                {/* ====== SECTION 5: Reset Password ====== */}
                {view === 'password' && <MyAccountResetPassword />}

                {/* ====== SECTION 6: Referral ====== */}
                {view === 'referral' && <MyAccountSendReferral />}

            </Container>

            <Row style={{ height: 120 }} />
        </Container>
    );
}

export default MyAccount;
