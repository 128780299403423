
// Import React Components
import React, { useState, useContext, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import Axios from 'axios';

// Import Data Fetching Stuff
import GlobalContext from '../../../context/GlobalContext.js';
import config from '../../../config.js';

// Import CBB Utils...
import CheckoutFormWrapper from '../stripe/CheckoutFormWrapper.js';
import PaymentMethodDisplay from '../../../components/uiUxItems/PaymentMethodDisplay.js';
import UpdatePaymentCardButton from '../stripe/buttons/UpdatePaymentCardButton.js';


// Create The Component
function MyAccountUpdateCard() {
    // useContext: Grab User Info
    const { userTier, userData } = useContext(GlobalContext);

    // useState: set page state
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    // console.log('paymentMethod: ', paymentMethod);

    // Get user's default Stripe payment method
    const fetchPaymentMethod = async () => {
        try {
            const apiBaseUrl = config.url.API_URL;
            const authHeader = { headers: { 'x-auth-token': userData.token } };
            const response = await Axios.get(`${apiBaseUrl}/stripe/get-payment-method`, authHeader);
            const hasCard = response?.data?.hasPaymentMethod || false;

            // set payment method
            setPaymentMethod(hasCard ? response.data.paymentMethod : null);
        } catch (err) {
            console.log('err: ', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch Payment Method & Payments as needed
    useEffect(() => {
        if (!userData.token) { return; }
        fetchPaymentMethod();
    }, [userData.token, userTier]);

    // shared section styles
    let sectionRowStyles = {
        borderBottom: '2px solid #222222',
        margin: 0,
        paddingTop: 8,
        justifyContent: 'space-between',
        alignItems: 'flex-end'
    };

    // and return !!
    return (<>
        <Row style={{ ...sectionRowStyles }}>
            <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                Update Credit Card
            </h3>
        </Row>
        <Row style={{ margin: 0, marginTop: 12 }}>
            <PaymentMethodDisplay
                paymentMethod={paymentMethod}
                isLoading={isLoading}
            />
        </Row>
        <Row style={{ margin: 0, marginTop: 20 }}>
            <CheckoutFormWrapper>
                <UpdatePaymentCardButton
                    style={{ maxWidth: 320 }}
                    currentPaymentMethod={paymentMethod}
                    onPaymentMethodUpdated={async (e) => setPaymentMethod(e)}
                />
            </CheckoutFormWrapper>
        </Row>
    </>);
}

export default MyAccountUpdateCard;
