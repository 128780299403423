
// react imports
import React, { useState, useRef } from 'react';
import { Alert, Form, Row, Col, Button } from 'react-bootstrap';
import Axios from 'axios';

// cbb imports
import config from '../../config';


function ContactUsForm({ className }) {
    // useState: set form state
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [alertLabel, setAlertLabel] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    // set form ref
    const formRef = useRef(null);

    // handle form submit - send email to me
    const handleEmailSend = async (e) => {
        // console.log('e: ', e);
        e.preventDefault();
        setIsSubmitting(true);

        // Grab data from form to submit...
        let submitData = {
            name: e.target.elements.name.value,
            team: e.target.elements.team.value,
            email: e.target.elements.email.value,
            phone: e.target.elements.phone.value,
            message: e.target.elements.message.value
        };

        try {
            let apiBaseUrl = config.url.API_URL;
            await Axios.post(`${apiBaseUrl}/email/contact`, { ...submitData });
            setAlertLabel('We received your message!');
        } catch (err) {
            setAlertLabel(err.response.data.msg);
        }

        setIsSubmitting(false);
        setShowAlert(true);

        // console.log('success! reset form now...');
        formRef.current.reset();
    };

    // constants
    // const colSize = className === 'footer-form' ? 12 : 6;
    const colSize = 12;

    // and return!
    return (
        <Form ref={formRef} onSubmit={handleEmailSend} className={`contact-us-form ${className}`}>
            <div className='contact-us-form__header'>
                <p className='p2'>Request a quote, ask questions, or report any concerns or issues with the website. Can also reach us by text at (516) 216-9085 or by email at nick@cbbanalytics.com.</p>
            </div>

            <Row style={{ marginTop: 15 }}>
                <Col xs={12} md={colSize}>
                    <Form.Row style={{ paddingTop: 5 }}>
                        <Form.Group as={Col} controlId='name'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control className='cbb-home-input' placeholder='required' />
                        </Form.Group>
                    </Form.Row>
                </Col>

                <Col xs={12} md={colSize}>
                    <Form.Row style={{ paddingTop: 5 }}>
                        <Form.Group as={Col} controlId='team'>
                            <Form.Label>Team or Company</Form.Label>
                            <Form.Control className='cbb-home-input' placeholder='required' />
                        </Form.Group>
                    </Form.Row>
                </Col>

                <Col xs={12} md={colSize}>
                    <Form.Row style={{ paddingTop: 5 }}>
                        <Form.Group as={Col} controlId='email'>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control className='cbb-home-input' type='email' placeholder='required' />
                        </Form.Group>
                    </Form.Row>
                </Col>

                <Col xs={12} md={colSize}>
                    <Form.Row style={{ paddingTop: 5 }}>
                        <Form.Group as={Col} controlId='phone'>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control className='cbb-home-input' placeholder='optional' />
                        </Form.Group>
                    </Form.Row>
                </Col>

                <Col xs={12}>
                    <Form.Row>
                        <Form.Group as={Col} controlId='message'>
                            <Form.Label>Message</Form.Label>
                            <Form.Control className='cbb-home-input' as='textarea' rows='2' placeholder='required' />
                        </Form.Group>
                    </Form.Row>
                </Col>
            </Row>

            {/* <Button className='okay' disabled variant='disabled' type='submit'> */}
            <Form.Row>
                <Form.Group as={Col} controlId='button'>
                    <Button variant='primary' type='submit' disabled={false}>
                        {isSubmitting ? 'Sending Email...' : 'Submit'}
                    </Button>
                </Form.Group>
                {showAlert &&
                    <Alert
                        variant={alertLabel === 'We received your message!' ? 'success' : 'danger'}
                        className='modal-alert animate-fade-away'
                        style={{ marginTop: 0 }}
                    >
                        {alertLabel}
                    </Alert>
                }
            </Form.Row>
        </Form>
    );
}

export default ContactUsForm;
