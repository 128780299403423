import React from 'react';
import { unitFormatter } from '../utils/GraphHelpers';

export default function AxisLeft({
    yScale,
    styleName = 'default',
    height,
    width,
    margin,
    count,
    label,
    isLoading,
    unit = 'int',
    showGrid = false,
    rotateLabel = false,
    override = {}, // { textFill, tickSize, labelSize }
    minify = false // if true, show title in bottom-left corner only
}) {
    // console.log('AxisLeft Props: ', { yScale, height, width, count, label, unit, margin });
    if (isLoading) { return null; }

    // Grab, Set Constants
    // ====================
    const textFill = override.textFill || ['dark', 'grey'].includes(styleName) ? '#ddd' : '#222';
    const tickSize = override.tickSize || 15;
    const labelSize = override.labelSize || 17;


    // Axis Label (Title)
    // ===================
    const { yUnits, tipUnits } = unitFormatter(unit);
    const axisLabel = (
        <g className='y-label'>
            {rotateLabel ?
                <text
                    x={-height / 1.6}
                    // y={-margin.left + 21} // {-40}
                    y={-0.71 * margin.left} // {-40}
                    dx={'2.0em'}
                    style={{ textAnchor: 'middle', fontSize: labelSize, fontWeight: 700, fill: textFill, transform: 'rotate(270deg)' }}
                >{label}</text> :
                <text
                    x={styleName === 'dark' ? -margin.left + 4 : 8} // {8}
                    y={0.01 * height}
                    style={{ fontSize: labelSize, fontWeight: 700, fill: textFill }}
                >{label}</text>
            }
        </g>
    );

    // Axis Label (small in corner)
    // =============================
    const axisTitleSmallInCorner = (
        <g className='y-label'>
            <text
                x={-height * 0.995}
                y={-0.1 * margin.left} // {-40}
                style={{ fontSize: labelSize - 4, fontWeight: 700, fill: textFill, transform: 'rotate(270deg)' }}
            >
                {label}
            </text>
        </g>
    );


    // Ticks and Grid
    // ===============
    const tickValues = yScale.ticks(count).map(d => yUnits(d));
    const allUniqueTicks = new Set(tickValues).size === tickValues.length;
    const thisFormatter = allUniqueTicks ? yUnits : tipUnits; // tipUnits has an extra decimal place
    const fontSizeBoost = Math.max(1 + (0.25 * ((count / tickValues.length) - 1)), 1);
    const axis = yScale.ticks(count).map((d, i) => {
        let textX = margin && ((-0.25 * margin.left) + (margin.leftAxisPad ? margin.leftAxisPad : 0)) || -10;
        let textY = yScale(d);
        // let textY = yScale(d);
        return (
            <g key={i} className='y-tick'>
                {showGrid &&
                    <line
                        style={{ stroke: 'rgba(0, 0, 0, 0.1)', strokeWidth: 0.6 }}
                        y1={yScale(d)}
                        y2={yScale(d)}
                        x1={0}
                        x2={width}
                    />
                }
                <text
                    style={{ fontSize: fontSizeBoost * tickSize, fill: textFill }}
                    x={textX}
                    y={textY}
                    textAnchor='middle'
                    dominantBaseline='middle'
                >
                    {`${thisFormatter(d)}`}
                </text>
            </g>
        );
    });

    // And Return
    // ==========
    return (<>
        {/* Standard Axis */}
        {!minify &&
            <g className='y-axis'>
                {axis}
                {axisLabel}
            </g>
        }

        {/* Minified Axis (small text in corner only) */}
        {minify &&
            <g className='y-axis'>
                {axisTitleSmallInCorner}
            </g>
        }
    </>);
}
