
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

// want this to be the default at some point...
const queryParamEndpoints = [
    'event-log-analytics',
    'get-dropdown-options',
    'pbp-shots-tying-go-ahead',
    'player-period-stats',
    'player-game-stats',
    'player-agg-stats',
    'player-agg-pbp-stats',
    'player-game-pbp-stats',
    'player-tying-go-ahead-shooting',
    'team-agg-pbp-stats',
    'team-game-stats',
    'team-game-pbp-stats',
    'team-tying-go-ahead-shooting',
    'team-records',
    'team-transfer-agg-stats',
    'user-tier-trial-tokens',
    'team-streaks',
    'team-agg-streak-stats',
    'game-explorer-search',
    'team-period-stats',
    'lg-zones',
    'nil-predictions',
    'raw-nil-anonymized',
    'enh-lineup-game-summaries',
    'lineup-agg-stats-top',
    'on-off-agg-stats',
    'pbp-shots',
    'pctiles',
    'ranks'
];

function loopIds(prefix, array) {
    // console.log('prefix, array: ', prefix, array);
    let url = prefix;
    if (array.length === 0) { return url + '/'; } // handle empty array, return "teams//", so :teams === ""
    // as an example, appends 'players/103032-302342-130324-230402/' to url
    array.forEach(id => (url += id + '-'));
    url = url.slice(0, -1) + '/';
    // console.log('url: ', url);
    return url;
}

// Used to create URL, given endpoint + config
function createUrl(endpoint, config, routeKey) {
    // endpoint:        the endpoint passed to useCBBQuery
    // config:          the config object passed to useCBBQuery
    // routeKey:        the routeKey passed to useCBBQuery

    // console.log('createURL params: ', { endpoint, config, routeKey });
    // This is very CBB-specific, based on how we need to access our data for pages on the website

    // Set Base of URL
    let url = process.env.NODE_ENV === 'development'
        ? `${process.env.REACT_APP_LOCALHOST_NODE_API}/${routeKey}`
        : `${process.env.REACT_APP_PRODUCTION_NODE_API}/${routeKey}`;

    // Handle passing, only works with routeKey of "gs"
    if (config.pass === true) { return url + '/pass'; }

    // console.log('url: ', url);
    const validEndpoints = [
        'competitions', 'tournaments', 'players', 'teams', 'games', 'conferences', 'competition-teams', 'competition-conferences', 'competition-team-players', // main entities
        'player-game-stats', 'player-period-stats', 'player-agg-stats', 'portal-agg-stats', // player box score stats
        'player-game-pbp-stats', 'player-agg-pbp-stats', 'portal-agg-pbp-stats', // player pbp stats
        'team-period-stats', 'team-game-stats', 'team-agg-stats', 'team-records', // team box score stats
        'team-game-pbp-stats', 'team-game-pppc', 'team-agg-pbp-stats', 'team-agg-pppc', // team game,period stats
        'team-transfer-agg-stats', 'conference-agg-stats', 'lineup-agg-stats-top', 'enh-lineup-game-summaries', 'on-off-agg-stats', 'team-agg-assist-combos', // other
        'enh-pbp', 'pbp-shots', 'lg-hexes', 'lg-zones', 'streaks-margins', 'shotcharts-top-options', 'game-flow-lineups', 'game-flow-players', // other
        'pbp-shots-tying-go-ahead', 'team-tying-go-ahead-shooting', 'player-tying-go-ahead-shooting', // game-tying and go-ahead shooting
        'ptgc-selects', 'live-search', // live search
        // percentiles
        'ranks', 'pctiles', 'pctile-cutoffs',
        // other
        'vc-transfer-portal', 'wbb-transfer-portal',
        'raw-games', 'live-games', 'live-games-two-day', 'match-actions', 'team-period-summaries', 'player-period-summaries',

        // miscellanous
        'event-log-analytics', 'team-colors', 'height-rankings', 'user-tier-trial-tokens',
        'team-streaks', 'team-agg-streak-stats', 'game-explorer-search',
        'nil-predictions', 'raw-nil-anonymized',

        // tournament-configs,
        'all-tournament-config-ids', 'tournament-configs',

        // bracket-definitions,
        'bracket-definitions',

        // /users/ routes
        'get-dropdown-options'
    ];

    const isInvalidEndpoint = !validEndpoints.includes(endpoint);
    if (isInvalidEndpoint) { console.log(`ERROR: ${endpoint} is invalid endpoint!`); }

    // Start url with the endpoint
    url = `${url}/${endpoint}/`;

    // If using query params, return after appending endpoint (after removing the trailing "/")
    if (queryParamEndpoints.includes(endpoint)) { return url.slice(0, -1); }

    // All Genius Routes Must Follow This Ordering for URLs
    if (config.dataSrc) { url += 'datasrc/' + config.dataSrc + '/'; }
    if (config.mongo) { url += 'mongo/' + config.mongo + '/'; }
    if (config.sex) { url += 'sex/' + config.sex + '/'; }
    if (config.competitionId) { url += 'competition/' + config.competitionId + '/'; }
    if (config.competitionIds) { url += loopIds('competitions/', config.competitionIds); }
    if (config.transferCompetitionIds) { url += loopIds('transfer-competitions/', config.transferCompetitionIds); }
    if (config.divisionId) { url += 'division/' + config.divisionId + '/'; }
    if (config.divisionIds) { url += loopIds('divisions/', config.divisionIds); }
    if (config.conferenceId || config.conferenceId === 0) { url += 'conference/' + config.conferenceId + '/'; }
    if (config.conferenceIds) { url += loopIds('conferences/', config.conferenceIds); }
    if (config.gameId) { url += 'game/' + config.gameId + '/'; }
    if (config.teamId) { url += 'team/' + config.teamId + '/'; }
    if (config.teamIds) { url += loopIds('teams/', config.teamIds); }
    if (config.playerId) { url += 'player/' + config.playerId + '/'; }
    if (config.playerIds) { url += loopIds('players/', config.playerIds); }
    if (config.scope) { url += 'scope/' + config.scope + '/'; }
    if (config.scopes) { url += loopIds('scopes/', config.scopes); }
    if (config.month) { url += 'month/' + config.month + '/'; }
    if (config.date) { url += 'date/' + config.date + '/'; }
    if (config.gender) { url += 'gender/' + config.gender + '/'; }
    if (config.zoneSchema) { url += 'zoneschema/' + config.zoneSchema + '/'; }
    if (config.tournamentId) { url += 'tournament/' + config.tournamentId + '/'; }
    if (config.bracketId) { url += 'bracket/' + config.bracketId + '/'; }
    if (config.metricType) { url += 'metricType/' + config.metricType + '/'; }
    if (config.qualifierType) { url += 'qualifierType/' + config.qualifierType + '/'; }
    if (config.onOffDiff) { url += 'onOffDiff/' + config.onOffDiff + '/'; }

    // If skinny
    if (config.tableType) { url += 'tabletype/' + config.tableType + '/'; }
    if (config.isHomePage === true) { url += 'home-skinny/'; }
    if (config.isCompsSkinny === true) { url += 'comps-skinny/'; }
    if (config.isSkinny === true) { url += 'skinny/'; }
    if (config.isDraft === true) { url += 'isdraft/' + config.isDraft + '/'; }
    if (config.isPortal === true) { url += 'portal/'; }

    // Other
    if (config.halfOrQuarter) { url += 'halfOrQuarter/' + config.halfOrQuarter + '/'; }
    if (config.ptgc) { url += 'ptgc/' + config.ptgc + '/'; }
    if (config.tableName) { url += 'tableName/' + config.tableName + '/'; }
    if (config.tableNames) { url += loopIds('tableNames/', config.tableNames); }
    if (config.transfers) { url += loopIds('transfers/', config.transfers); }
    if ([true, false].includes(config.isOffense)) { url += 'isoffense/' + config.isOffense + '/'; }
    if (config.perWhat) { url += 'perwhat/' + config.perWhat; }
    if (config.isQualified) { url += 'is-qualified/'; }
    if (config.gameDate) { url += 'gamedate/' + config.gameDate + '/'; }
    if (config.gameDates) { url += 'gamedates/'; }
    if (config.start) { url += 'start/' + config.start + '/'; }
    if (config.end) { url += 'end/' + config.end + '/'; }
    if (config.oppoInConf) { url += 'opp-in-conf/'; }
    if (config.latest) { url += 'latest/' + config.latest + '/'; }
    if (config.isLatest) { url += 'latest/' + config.isLatest + '/'; }
    if (config.transfer) { url += 'transfer/'; }
    if (config.isTransfer) { url += 'transfer/'; }
    if (config.willTransfer) { url += 'will-transfer/'; }
    if (config.v2) { url += 'v2/'; }
    if (config.teamRecords) { url += 'team-records/'; }
    if (config.fields) { url += loopIds('fields/', config.fields); }

    // And Return
    return (url);
}


const fetchData = async ({ fetchUrl, signal }) => {
    try {
        // grant token
        let token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');

        // fetch data
        let res = await axios.get(fetchUrl, {
            headers: { 'x-auth-token': token },
            signal
        });
        // Return data on success
        return res.data;
    } catch (error) {
        // Pass error messages through
        if (error.response) {
            // Server responded with a status code other than 2xx
            throw new Error(error.response.data.msg || error.response.data.error || 'An unknown error occurred.');
        } else if (error.request) {
            // No response received from server
            throw new Error('No response received from server.');
        } else {
            // Axios error during setup
            throw new Error(`Request setup failed: ${error.message}`);
        }
    }
};

const fetchDataQp = async ({ fetchUrl, config, signal }) => {
    // get fetchUrl and token
    let token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');

    // fetch data
    let res = await axios.get(fetchUrl, {
        headers: { 'x-auth-token': token },
        params: config,
        signal
    });

    // and return
    return res.data;
};


// Wrap the existing fetch functions to handle string responses
// This is effort to handle the array -> string error we have been struggling with
const wrapFetchFn = (originalFn) => async (params) => {
    const response = await originalFn(params);
    if (typeof response === 'string') {
        try {
            return JSON.parse(response);
        } catch (e) {
            // log the actual malformed data for debugging
            console.error('Received malformed string data:', response);

            // throw error with both message and original data
            throw new Error('Received malformed data from server', {
                cause: {
                    originalData: response,
                    endpoint: params.signal?.url || params.fetchUrl
                }
            });
        }
    }
    return response;
};

const useCBBQuery = ({ ep, routeKey = 'gs', cfg = {}, optionsConfig = {}, refetchKey = 0, refetchInterval = null }) => {
    // ep:              endpoint
    // routeKey:        the base part of the URL for the route, one of [gs, gsl, users, email, stripe]
    // cfg:             parameters config
    // optionsConfig:   react-query options config
    // refetchKey:      integer that we increment to trigger refetching, add to queryKey array
    // refetchInterval: integer seconds that we use to set the refetch interval, default is 0 secs (no refetching) - NC: this seems better than refetchKey

    // create queryKey, return useQuery using fetchData
    const cfgString = JSON.stringify(cfg);
    const queryKey = [ep, cfgString, refetchKey];
    const isDisabled = cfg && cfg.pass === true;

    // create fetch URL
    let fetchUrl = createUrl(ep, cfg, routeKey);

    // determine query function:
    const queryType = queryParamEndpoints.includes(ep) ? 'fetchDataQp' : 'fetchData';

    // set query function for useQuery
    let queryFn;
    switch (queryType) {
        case 'fetchDataQp': queryFn = async ({ signal }) => fetchDataQp({ fetchUrl, config: cfg, signal }); break;
        case 'fetchData': queryFn = async ({ signal }) => fetchData({ fetchUrl, signal }); break;
        default: console.log('Error: impossible queryType');
    }

    // create main query using useQuery()
    // if (refetchInterval) {
    //     console.log('refetchInterval: ', refetchInterval);
    //     console.log('QUERY KEY FOR FETCH: ', queryKey);
    //     console.log({ staleTime: refetchInterval || Infinity, refetchInterval });
    // }
    const query = useQuery({
        queryKey,
        queryFn: wrapFetchFn(queryFn),
        enabled: !isDisabled,
        refetchOnWindowFocus: false, // false
        refetchOnMount: false, // added
        refetchOnReconnect: false, // added
        // Make staleTime slightly less than refetchInterval
        staleTime: refetchInterval ? refetchInterval - 100 : Infinity,
        refetchInterval,
        cacheTime: refetchInterval ? refetchInterval + 100 : 20 * 60 * 1000,
        retry: (failureCount, error) => { // retry up to 3 times if we get a string response
            if (error?.message === 'Received malformed data from server') {
                return failureCount < 3;
            }
            return optionsConfig.retry ?? false; // don't retry other errors unless specified in optionsConfig
        },
        onError: (error) => {
            if (error?.message === 'Received malformed data from server') {
                Sentry.captureException(error, {
                    extra: {
                        originalData: error.cause?.originalData,
                        endpoint: error.cause?.endpoint
                    }
                });
            }
            // Call user's onError if provided
            optionsConfig.onError?.(error);
        },
        ...optionsConfig
    });

    // in new v4 react-query: when "enabled" is false, isLoading is always "true". this is problem for skipping queries
    // this fixes itself in v5, see https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5#status-loading-has-been-changed-to-status-pending-and-isloading-has-been-changed-to-ispending-and-isinitialloading-has-now-been-renamed-to-isloading
    return {
        ...query,
        isLoading: query.isLoading && query.fetchStatus !== 'idle',
        data: query.isError && query.error?.message === 'Received malformed data from server' // provide fallback data based on expected type
            ? (Array.isArray(query.data) ? [] : null)
            : query.data
    };
};

export default useCBBQuery;
