// 4154179448017283

// React & Stripe Libraries
import React, { useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Row, Col } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import Axios from 'axios';

import CBBCardElement from './CBBCardElement';
import PaymentAlert from './PaymentAlert';
import PaymentMethodDisplay from '../../../../components/uiUxItems/PaymentMethodDisplay';
import PromoCodeInputForm from './PromoCodeInputForm';

import CBBSelect from '../../../../components/selects/CBBSelect';
import config from '../../../../config';
import GlobalContext from '../../../../context/GlobalContext';

const StartSubscriptionPanel = ({
    toTier = 'userTier',
    subscription,
    style = {},
    paymentMethod = null
}) => {
    // console.log('StartSubscriptionPanel props: ', { style, paymentMethod, subscription, toTier });
    const [queryParameters] = useSearchParams();
    const referralCode = queryParameters.get('referral'); // NC Stripe: should we fallback to ''? code: in useState() used to be default to ''

    // useState: Set Button State
    const [isProcessing, setIsProcessing] = useState(false);
    const [checkoutError, setCheckoutError] = useState();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [referral, setReferral] = useState({ promoCode: null, code: referralCode, discount: 0, color: 'black', message: '' });
    const [useExistingCard, setUseExistingCard] = useState(false);
    const [isCardComplete, setIsCardComplete] = useState(false);
    const [price, setPrice] = useState(toTier === 'userTier'
        ? { value: 30, label: '$30 / Month', text: '$30 / month (billed monthly)' }
        : { value: 60, label: '$60 / Month', text: '$60 / month (billed monthly)' }
    );

    // Grab User Data, State of Free Trial
    const { userData, userTier, setUserData } = useContext(GlobalContext);
    const usedTrial = userData.user ? userData.user.usedTrial : true;
    const isActiveStripeSub = ['active', 'trialing'].includes(subscription?.status || '');
    const isNonStripeTier3 = userTier.value === 3 && !isActiveStripeSub;
    const isTier3 = userTier.value >= 3;
    const isPaidTier2 = userTier.value === 2 && (userData?.user?.tier?.free || false) !== true; // better to base this on whether user has active account in stripe?

    // Handle Stripe Stuff
    const stripe = useStripe();
    const elements = useElements();

    // Handle Credit Card
    const handleCardChange = (e) => {
        setIsCardComplete(e.complete);
    };

    const handleSubmit = async () => {
        setIsProcessing(true);
        const cardElement = elements.getElement('card');
        try {
            // Grab Stripe Elements + Variables needed to post payment subscription
            const apiBaseUrl = config.url.API_URL;

            let thisPaymentMethod = null;
            let useNewCard = !useExistingCard || paymentMethod === null;
            if (useNewCard) {
                cardElement.update({ disabled: true });
                const paymentMethodReq = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement
                });

                // Handle Bad Payment Method
                if (paymentMethodReq.error) {
                    console.log('Payment Error', paymentMethodReq.error);
                    setCheckoutError(paymentMethodReq.error.message);
                    setShowErrorAlert(true);
                    setIsProcessing(false);
                    cardElement.update({ disabled: false });
                    return;
                }

                thisPaymentMethod = paymentMethodReq.paymentMethod.id; // new payment method
            } else {
                thisPaymentMethod = paymentMethod; // existing payment method
            }

            // Handle Creating Payment Subscription
            const paymentObject = {
                useExistingCard: useExistingCard,
                newPaymentMethod: thisPaymentMethod,
                priceKey: price.value,
                promoCode: referral.promoCode,
                referralCode: referral.code
            };
            const authHeader = { headers: { 'x-auth-token': localStorage.getItem('auth-token') } };
            const response = await Axios.post(`${apiBaseUrl}/stripe/payment-subscriptions`, paymentObject, authHeader);

            // FB pixel tracking for starting subscriptions (only if window.fbq function exists)
            const fbTrackSubscription = typeof window !== 'undefined' && typeof window.fbq === 'function';
            if (fbTrackSubscription) {
                const eventToTrack = usedTrial === true ? 'Subscribe' : 'StartTrial';
                window.fbq('track', eventToTrack);
            }

            setUserData({ token: userData.token, user: response.data.user });
            setShowSuccessAlert(true);
            setIsProcessing(false);
        } catch (error) {
            console.log('Err: payment error: ', error);
            if (cardElement) {
                cardElement.update({ disabled: false });
            }
            const errorMessage = error?.response?.data?.err?.message || error?.response?.data?.message || error.message || 'An error occurred processing your payment';
            setCheckoutError(errorMessage);
            setShowErrorAlert(true);
            setIsProcessing(false);
        }
    };

    // Button to toggle payment options
    // New Card, Existing Card Buttons
    let existingCardOptions = [false, true];
    let existingCardButtons = (<>
        <Row style={{ margin: 0, marginBottom: -2 }}>
            <span style={{ fontSize: '0.9em', color: '#444', marginRight: 4 }}>
                {toTier === 'userTier' && 'User Tier Payment Options and Discount Code'}
                {toTier === 'proTier' && 'Pro Tier Payment Options'}
            </span>
        </Row>
        <Row style={{ marginRight: -1, marginLeft: -1 }}>
            {existingCardOptions.map(tab => (
                <Col
                    xs={6} sm={6}
                    key={`existing-card-${tab}`}
                    style={{ width: '100%', paddingRight: 1, paddingLeft: 1 }}
                >
                    <span
                        className={`cbb-button ${tab === useExistingCard ? 'selected' : ''} ${isProcessing ? 'disabled' : ''}`}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => setUseExistingCard(tab)}
                    >
                        {tab === true ? 'Use Card on File' : 'New Payment Method'}
                    </span>
                </Col>
            ))}
        </Row>
    </>);

    // User Tier Options: 1-Month, 3-Month, 6-Month, 12-Month Options
    let userTierPriceSelect = (<>
        <Row style={{ margin: 0, marginBottom: -3 }}>
            <span style={{ fontSize: '0.9em', color: '#444', marginRight: 5 }}>Payment Intervals</span>
        </Row>
        <Row style={{ flexDirection: 'column', margin: 0, marginBottom: 16 }}>
            <CBBSelect
                selectType='subscriptionPackages'
                width='none'
                value={price}
                setValue={setPrice}
                isDisabled={isProcessing}
                optionGroup='userTier'
            />
            <span style={{ fontSize: '0.875em', lineHeight: 1.3, marginTop: 3, marginLeft: 2 }}>{price.text}</span>
        </Row>
    </>);

    let proTierPriceSelect = (<>
        <Row style={{ margin: 0, marginTop: 10, marginBottom: -3 }}>
            <span style={{ fontSize: '0.9em', color: '#444', marginRight: 5 }}>Payment Intervals</span>
        </Row>
        <Row style={{ flexDirection: 'column', margin: 0, marginBottom: 16 }}>
            <CBBSelect
                selectType='subscriptionPackages'
                width='none'
                value={price}
                setValue={setPrice}
                isDisabled={isProcessing}
                optionGroup='proTier'
            />
            <span style={{ fontSize: '0.875em', lineHeight: 1.3, marginTop: 3, marginLeft: 2 }}>{price.text}</span>
        </Row>
    </>);

    let promoCodeInputForm =
        (<PromoCodeInputForm
            referral={referral}
            setReferral={setReferral}
            isDisabled={isProcessing}
            wrapperStyle={{ marginBottom: 15 }}
        />);

    // main submit button
    const isButtonDisabled = isProcessing || !stripe || isPaidTier2 || isActiveStripeSub || isNonStripeTier3 || (!useExistingCard && !isCardComplete) || (useExistingCard && !paymentMethod);
    const isActiveProTier = isActiveStripeSub && [6000, 60000].includes(subscription?.plan?.amount);
    const isActiveUserTier = isActiveStripeSub && [3000, 8500, 16000, 29900].includes(subscription?.plan?.amount);
    const submitPaymentButton =
        (<button
            // className={`stripe-button ${isButtonDisabled ? 'disabled' : ''}`}
            className={`stripe-button ${isActiveStripeSub ? 'green' : ''}`}
            onClick={handleSubmit}
            disabled={isButtonDisabled}
        >
            {isNonStripeTier3 ? 'Cannot Subscribe to User Tier from Pro Tier Account'
                : isActiveProTier ? 'Have Active Pro Tier Subscription'
                    : isActiveUserTier ? 'Have Active User Tier Subscription'
                        : isPaidTier2 ? 'You Are Subscribed!'
                            : isProcessing ? 'Processing...'
                                : useExistingCard && !paymentMethod ? 'No Cards on File - Use New Card'
                                    : (!useExistingCard && !isCardComplete) ? 'Enter New Card'
                                        : `Start Subscription - ${price.label}`}
        </button>);


    // Buttons
    // =========
    // start-subscription-button unused in scss at the moment, just for naming purposes
    return (<div className='start-subscription-panel' style={{ ...style }}>
        <Row>
            {/* For Widgers (1) Determine New vs Old Payment Method, (2) Plan Select, and (3) Discount Code Input */}
            {!isPaidTier2 && !isTier3 && <>
                <Col xs={12} sm={6} style={{ margin: 0 }}>
                    {/* {header1} */}
                    {existingCardButtons}
                    {toTier === 'userTier' && promoCodeInputForm}
                    {toTier === 'userTier' && userTierPriceSelect}
                    {toTier === 'proTier' && proTierPriceSelect}
                </Col>
            </>}


            {/* Toggle Between Existing Card on File & New Card */}
            {!isPaidTier2 && !isTier3 && paymentMethod && useExistingCard &&
                <Col xs={12} sm={6} style={{ margin: 0, justifyContent: 'space-between', alignItems: 'center', fontSize: '0.9em', color: '#444', marginBottom: 5 }}>
                    <PaymentMethodDisplay
                        paymentMethod={paymentMethod}
                        isLoading={false}
                    />
                </Col>
            }


            {/* Part 3: Input Card Number and Submit */}
            {/* ==================================== */}
            <Col xs={12} sm={6}>
                {/* Text Above Payment Box */}
                {!isPaidTier2 && !isTier3 &&
                    <div style={{ margin: 0, justifyContent: 'space-between', alignItems: 'center', fontSize: '0.9em', color: '#444' }}>
                        <span style={{ marginRight: 5 }}>Payment Information Secure</span>
                        <FaLock size={12} stroke='#444' />
                    </div>
                }

                {/* Fill Out Credit Card Information (cannot unrender, or card errors thrown. better to display: none) */}
                {!isPaidTier2 && !isTier3 &&
                    <div style={{ margin: 0, marginBottom: 5, display: useExistingCard ? 'none' : 'flex' }}>
                        <CBBCardElement
                            onChange={handleCardChange}
                        />
                    </div>
                }

                {/* Main Submit-Payment Button */}
                <div style={{ margin: 0, marginBottom: 5 }}>
                    {submitPaymentButton}
                </div>

                {/* Has or Hasnt Used Trial Yet Button */}
                {userTier.value === 1 &&
                    <div style={{ lineHeight: 1.1, margin: '-3px 0px 5px 0px' }}>
                        {usedTrial &&
                            <span style={{ fontSize: '0.85em', fontStyle: 'italic', lineHeight: 1.3 }}>
                                Already used 1-week trial. Your card will be charged when you subscribe. Cancel anytime from this page.
                            </span>
                        }
                        {!usedTrial &&
                            <span style={{ fontSize: '0.85em', fontStyle: 'italic' }}>
                                Subscription includes 1-week free trial. <strong style={{ color: 'darkgreen' }}>Card is not charged until after trial.</strong> Cancel anytime.
                            </span>
                        }
                    </div>
                }
            </Col>
        </Row>
        {/* Alerts (Success & Fail) */}
        <Row style={{ margin: '20px 0px 5px 0px', justifyContent: 'center' }}>
            <PaymentAlert
                variant='success'
                showAlert={showSuccessAlert}
                setShowAlert={setShowSuccessAlert}
                alertMessage={'Signup Successful!'}
            />
            <PaymentAlert
                variant='danger'
                showAlert={showErrorAlert}
                setShowAlert={setShowErrorAlert}
                alertMessage={checkoutError}
            />
        </Row>
    </div>);
};

export default StartSubscriptionPanel;
